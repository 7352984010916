import ownerInit from './owner.mock';
import waiterInit from './waiter.mock';
import menuInit from './menu.mock';
import orderInit from './orders.mock';
import profileInit from './profile.mock';
import tablesInit from './tables.mock';

export function initMocks() {
  const baseURL = process.env.REACT_APP_SERVER_URL || '';

  ownerInit(baseURL);
  waiterInit(baseURL);
  menuInit(baseURL);
  orderInit(baseURL);
  profileInit(baseURL);
  tablesInit(baseURL);
}
