import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.fonts.xl};
  margin: 0 0 ${({ theme }) => theme.spaces.lg};
`;
const toggleLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.black}; 
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const InputGroup = styled.div`
 width: 330px;
  margin-bottom: ${({ theme }) => theme.spaces.sm}
`;
const ButtonWrapper = styled.div`
  width: 220px;
`;

export const Styled = {
  InputGroup,
  ButtonWrapper,
  Form,
  Subtitle,
  toggleLink,
};
