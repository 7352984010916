import styled from 'styled-components';
import ArrowDown from '../../../../assets/arrow-down.png';
import { IIndicatorColors } from '../../types';

interface IOption {
  indicator?: IIndicatorColors,
}

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const CurrentOption = styled.span<IOption>`
  display: flex;
  justify-content: center;
  align-items: center;
  &:after{
    position: absolute;
    right: 0;
    content: '';
    background-image: url(${ArrowDown});
    background-size: contain;
    width: 13px;
    height: 8px;
    display: block;
    margin-left: ${({ theme }) => theme.spaces.xs};
  }
  ${({ indicator, theme }) => indicator ?
  `&:before{
    content: '';
    position: absolute;
    background-color: ${theme.colors[indicator]};
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 0px;
  }`: null}
  &:hover{
    cursor: pointer;
  }`;

const SelectOption = styled.div`
  width: 100%;
  position: absolute;
  top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spaces.xs} ${({ theme }) => theme.spaces.xs};
  z-index: 2;
  box-shadow: 1px 2px 2px ${({ theme }) => theme.colors.black};
`;

const Option = styled.span`
  text-align: center;
  padding: ${({ theme }) => theme.spaces.xxs} 0;
  width: 160px;

  &:hover{
    cursor: pointer;
  }
`;

export const Styled = {
  SelectWrapper,
  CurrentOption,
  SelectOption,
  Option,
};
