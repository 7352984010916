import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { IMap } from '../../types';
import { Button } from '../common/components/button';
import { PageTitle } from '../common/components/page-title';
import { Table } from '../common/components/table';
import { Alignment } from '../common/constants';
import { auth } from '../common/services/auth.service';
import { ITableColumn, TableCellType } from '../common/types';
import { ITable } from '../orders/types';
import { TableModal } from './components/table-modal';
import { TableButton } from './components/tables-cells/table-button';
import { TableName } from './components/tables-cells/table-name';
import { TableStatus } from './components/tables-cells/table-status';
import { tablesService } from './services/tables.service';
import { Styled } from './tables.styled';
import { sortTables } from './utils/sort-tables';

export const Tables:FC = () => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const [editTableObject, setEditTableObject] = useState<ITable | null>(null);

  const fetchTables = () => tablesService.fetchTables();
  const { isRefetching, refetch, data, isLoading } = useQuery('tables', fetchTables);
  const sortedData = data ? sortTables(data) : null;

  const onCloseModal = () => {
    setEditTableObject(null);
    setIsModal(false);
  };

  const onEditTableClick = (table: ITable) => {
    setEditTableObject(table);
    setIsModal(true);
  };

  const onRefreshHandler = () => {
    refetch();
  };

  const columns:ITableColumn[] = [
    {
      title: 'Name',
      key: 'name',
      type: TableCellType.TableName,
      Component: TableName,
    },
    {
      title: 'Status',
      key: 'status',
      type: TableCellType.TableStatus,
      Component: TableStatus,
    },
  ];
  auth.isOwner() && columns.push(
      {
        title: 'Edit Table',
        key: 'edit',
        type: TableCellType.TableStatus,
        Component: TableButton,
        onClick: onEditTableClick,
      });

  return (
    <>
      <Styled.Wrapper>
        <PageTitle>TABLES</PageTitle>
        <Styled.ButtonWrapper>
          <Button
            background="primary"
            rounded={true}
            onClick={onRefreshHandler}
          >Refresh</Button>
        </Styled.ButtonWrapper>
      </Styled.Wrapper>
      {isLoading || isRefetching ?
        'Loading...' :
        (
          <Table
            columns={columns}
            data={sortedData as IMap}
            alignment={Alignment.START}
          />
          )
      }
      <TableModal
        isOpen={isModal}
        onRequestClose={onCloseModal}
        table={editTableObject as ITable}
      />
    </>
  );
};
