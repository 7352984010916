import React, { FC } from 'react';
import { auth } from '../../../common/services/auth.service';
import { ITab, IOrdersTab } from '../../types';
import { OrderTab } from '../order-tab';
import { Styled } from './order-tabs.styled';

export interface IOrdersTabsProps{
  currentTab: IOrdersTab
  setCurrentTab: (tab: IOrdersTab) => void,
}

export const OrderTabs:FC<IOrdersTabsProps> = ({ currentTab, setCurrentTab }) => {
  const waitersTabs: ITab[] = [
    {
      title: 'Orders',
      slug: 'orders',
    },
    {
      title: 'Orders history',
      slug: 'history',
    },
    {
      title: 'My orders',
      slug: 'myOrders',
    },
  ];
  const ownersTabs: ITab[] = [
    {
      title: 'Orders',
      slug: 'orders',
    },
    {
      title: 'Orders history',
      slug: 'history',
    },
  ];
  const tabs: ITab[] = auth.isWaiter() ? waitersTabs : ownersTabs;

  const Tabs = tabs.map((tab: ITab, index: number) => {
    return <OrderTab
      key={index}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      {...tab}
    />;
  });

  return (
    <Styled.TabsWrapper>
      {Tabs}
    </Styled.TabsWrapper>
  );
};
