import React, { FC } from 'react';
import { Styled } from './add-waiter-card.styled';

interface IAddWaiterCardProps{
  onClick: () => void;
}

export const AddWaiterCard:FC<IAddWaiterCardProps> = ({ onClick }) => {
  return (
    <Styled.Card onClick={onClick}>
      <Styled.Plus>+</Styled.Plus>
      <Styled.Subtitle>Add new waiter</Styled.Subtitle>
    </Styled.Card>
  );
};
