import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AddWaiter } from '../../../modules/add-waiter';
import { Sidebar } from '../../../modules/common/components/sidebar';
import { ErrorPage } from '../../../modules/error';
import { Home } from '../../../modules/home/components/content';
import { MenuPage } from '../../../modules/menu';
import { ConfigureDish } from '../../../modules/menu/components/configure-dish';
import { Orders } from '../../../modules/orders';
import { OrderDetail } from '../../../modules/orders/components/order-detail';
import { Tables } from '../../../modules/tables';
import { PrivateRoute } from '../../private-route';
import { OwnerRoute } from '../owner-route';
import { Styled } from './main-content.styled';

export const MainContent:FC = () => {
  return (
    <Styled.AppWrapper>
      <Sidebar/>
      <Styled.Content>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/orders" component={Orders} />
          <PrivateRoute exact path="/orders/:id" component={OrderDetail} />
          <PrivateRoute exact path="/tables" component={Tables} />
          <PrivateRoute path="/menu/menu-item/:id" component={ConfigureDish} />
          <OwnerRoute path="/menu/menu-item" component={ConfigureDish} />
          <OwnerRoute exact path="/menu" component={MenuPage} />
          <OwnerRoute exact path="/add-waiter" component={AddWaiter} />
          <Route path="/error" component={ErrorPage}/>
          <Route path="*">
            <ErrorPage />
          </Route>
          <Route path="/login">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Styled.Content>
    </Styled.AppWrapper>
  );
};
