import React, { FC } from 'react';
import { Styled } from './login-form.styled';
import { LoginForm } from '../login-form';
import { userRoles } from '../../../common/constants';
import { authOwnerService } from '../../services/owner-login.service';
import { ILoginFormProps } from '../../types';

export const LoginFormOwner:FC<ILoginFormProps> = ({ setIsAuth }) => {
  return (
    <>
      <Styled.Subtitle>
        I am an owner, <Styled.toggleLink to="/login/waiter">login as waiter</Styled.toggleLink>
      </Styled.Subtitle>
      <LoginForm
        role={userRoles.owner}
        setIsAuth={setIsAuth}
        onSumbitTarget={authOwnerService}
      />
    </>
  );
};
