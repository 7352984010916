import React, { FC, useRef, useState } from 'react';
import { orderStatuses } from '../../../orders/constants';
import { useOutsideClicker } from '../../hooks/useOutsideClicker';
import { auth } from '../../services/auth.service';
import { IOption } from '../../types';
import { Styled } from './select.styled';

interface ISelectProps {
  options: IOption[]
}

export const Select:FC<ISelectProps> = ({ options }) => {
  const [showOptionsMenu, setShowOptionsMenu] = useState<boolean>(false);
  const select = useRef<HTMLDivElement>(null);
  const current = options.filter((option) => option.current)[0];

  const optionsWithoutCurrent = options.filter((option) => !option.current);
  const optionsFiltered = auth.isWaiter() ? optionsWithoutCurrent :
    optionsWithoutCurrent.filter((option) => option.name !== orderStatuses.accepted);

  const optionsElements = optionsFiltered.map((option: IOption, index:number ) => {
    const onClickHandler = () => {
      option.onClick();
      setShowOptionsMenu(false);
    };

    return (
      <Styled.Option
        key={index}
        onClick={onClickHandler}
      >{option.name}</Styled.Option>
    );
  });

  useOutsideClicker(select, setShowOptionsMenu);

  const onOptionClickHandler = () => {
    setShowOptionsMenu(true);
  };


  return (
    <Styled.SelectWrapper>
      <Styled.CurrentOption
        indicator={current?.indicator}
        onClick={onOptionClickHandler}
      >{current?.name}</Styled.CurrentOption>
      {showOptionsMenu && (
        <Styled.SelectOption
          ref={select}
        >
          {optionsElements}
        </Styled.SelectOption>
      )}
    </Styled.SelectWrapper>
  );
};
