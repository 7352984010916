import styled from 'styled-components';

const ErrorBox = styled.div<{ isEdit?: boolean, isErrorExists?:string}>`
  color: ${({ theme }) => theme.colors.red};
  margin-top: ${({ theme, isEdit, isErrorExists }) => (!isEdit && isErrorExists) || isEdit ? theme.spaces.xs : '0' };
  position: ${({ isEdit }) => isEdit ? 'absolute' : 'initial'};
  left:0;
  top: ${({ isEdit }) => isEdit ? '90%' : '0'};
`;
export const Styled = {
  ErrorBox,
};
