import React, { FC } from 'react';
import { AppModal } from '../app-modal';
import { Button } from '../common/components/button';
import { Styled } from './logout.styled';


interface LogoutProps{
  isOpen: boolean,
  onRequestClose: (event: React.MouseEvent) => void,
  onSubmit: (event: React.MouseEvent) => void,
};

export const LogoutModal:FC<LogoutProps> = ({ isOpen, onRequestClose, onSubmit }) => {
  return (
    <AppModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Styled.ModalTitle>Are you sure you want to exit?</Styled.ModalTitle>
      <Button
        background="primary"
        onClick={onSubmit}
      >Yes</Button>
      <Button
        background="secondary"
        onClick={onRequestClose}
      >No</Button>
    </AppModal>
  );
};
