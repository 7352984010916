import React, { FC, useState } from 'react';
import { IUser } from '../../types';
import { WaiterAdd } from './components/waiter-add';
import { WaiterEdit } from './components/waiter-edit';
import { WaiterList } from './components/waiter-list';
import { WaiterMode } from './types';
import { Styled } from './add-waiter.styled';


export const AddWaiter:FC = () => {
  const [mode, setMode] = useState<WaiterMode>('list');
  const [currentWaiter, setCurrentWaiter] = useState<IUser | null>(null);

  const components = {
    list: <WaiterList setMode={setMode} setCurrentWaiter={setCurrentWaiter}/>,
    edit: <WaiterEdit setMode={setMode} currentWaiter={currentWaiter}/>,
    add: <WaiterAdd setMode={setMode}/>,
  };

  return (
    <Styled.Wrapper>
      {components[mode]}
    </Styled.Wrapper>
  );
};
