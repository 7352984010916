import type { DefaultTheme } from 'styled-components';
import { colorPalette } from './color-palette';
import { fontSizes } from './font-sizes';
import { spaces } from './spaces';

export const theme: DefaultTheme = {
  colors: colorPalette,
  fonts: fontSizes,
  spaces: spaces,
};
