import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spaces.xs} ${theme.spaces.sm}`};
  margin-bottom: ${({ theme }) => theme.spaces.xs};
  box-sizing: border-box;
`;

const BasicIcon = styled.div`
  width: 65px;
  height: 56px;
  background: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ToolsWrapper = styled(Wrapper)`
  width: 90px;
  margin-left: ${({ theme }) => theme.spaces.xs};
`;

const Dish = styled.h5`
  width: 25%;
  margin: 0 ${({ theme }) => theme.spaces.sm} 0 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.lg};
  line-height: 23px;
  letter-spacing: -0.3px;
  word-break: break-all;
`;

const Ingridients = styled.span`
  width: 45%;
  height: fit-content;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.sm};
  line-height: 16px;
  letter-spacing: -0.3px;
  word-break: break-all;

`;

const ReorderIcon = styled.img`
  margin-right: ${({ theme }) => theme.spaces.sm};
`;

const Icon = styled.img`
  width: 65px;
  height: 56px;
  object-fit: cover;
`;

const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spaces.xs};
`;

export const Styled = {
  Content,
  Wrapper,
  Dish,
  Ingridients,
  ReorderIcon,
  IconWrapper,
  Icon,
  BasicIcon,
  ToolsWrapper,
};
