import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import qs from 'qs';
import { Styled } from './configure-dish.styled';
import { IDishOption, IDishPayload, IMenuItemOptionMutationPayload } from '../../types';
import { FormInput } from '../../../common/components/form-input';
import { ImageDropzone } from '../../../common/components/dropzone';
import { menuService } from '../../services/menu.service';
import { validationSchema } from '../../utils/configure-dish-vlidation-schema';
import { Button } from '../../../common/components/button';
import { TextArea } from '../../../common/components/textarea';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EditOptionsModal } from '../options-modal';
import { menuItemOptionService } from '../../services/menu-item-option.service';

interface IDishParams{
  id: string
}

export const ConfigureDish = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<IDishOption[]>([]);
  const queryClient = useQueryClient();
  const location = useLocation();
  const params: unknown = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { categoryId } = params as any;
  const history = useHistory();
  const { id } = useParams<IDishParams>();
  const { data: dish } = useQuery(['menu-item', id], () => {
    return id ? menuService.getMenuItem(id) : null;
  });

  const editMenuItemMutation = useMutation(( { dishId, dish }:any) =>
    menuService.editMenuItem(dishId, dish), {
    onSuccess: () => {
      queryClient.invalidateQueries('menu');
    },
  });

  const addMenuItemMutation = useMutation(( { dish }:any) =>
    menuService.addMenuItem(dish), {
    onSuccess: () => {
      queryClient.invalidateQueries('menu');
    },
  });

  const onSubmit = (dish: IDishPayload) => {
    const { menuItemOptions, ...dishWithoutOptions } = dish;
    id ? editMenuItemMutation.mutate({ dishId: id, dish: dishWithoutOptions }) :
      addMenuItemMutation.mutate({
        dish: { ...dishWithoutOptions, menuItemOptions: menuItemOptions },
      });
  };

  const back = () => {
    history.goBack();
  };
  const [loadedIcon, setLoadedIcon] = useState(dish?.media || '');

  const formik = useFormik({
    initialValues: {
      name: dish?.name || '',
      media: dish?.media || '',
      description: dish?.description || '',
      price: Number(dish?.price) || 0,
      available: dish?.available || false,
    },
    onSubmit: ({ media, name, description, price, available }: IDishPayload) => {
      const processedOptions = options?.map((option) => {
        return { name: option.name, price: option.price };
      });
      const newDish = {
        media,
        name,
        description,
        price,
        available: !!available,
        menu: {
          id: dish?.menu?.id || categoryId,
        },
        menuItemOptions: processedOptions || [],
      };

      onSubmit(newDish);
      back();
    },
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    setLoadedIcon(dish?.media || '');
  }, [dish?.media]);

  useEffect(() => {
    formik.setFieldValue('media', loadedIcon);
  }, [loadedIcon]);

  const setFormikMedia = () => formik.setFieldValue('media', loadedIcon);
  const clearImage = () => setLoadedIcon('');

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { mutate: createItem } = useMutation(( { id, option }: IMenuItemOptionMutationPayload) =>
    menuItemOptionService.addMenuItemOption(id, option), {
    onSuccess: () => {
      queryClient.invalidateQueries('menu-item');
    },
  });

  const createNewOptionForNewItem = (optionName: string, optionPrice: number ) => {
    setOptions((prev) => {
      const newOption: IDishOption = {
        name: optionName,
        price: optionPrice,
        id: prev.length ? (+prev[prev.length -1].id + 1).toString() : '1',
      };
      return [...prev, newOption];
    });
  };

  const createNewOptionForExistedItem = (optionName: string, optionPrice: number) => {
    const newOption = { name: optionName, price: optionPrice };
    createItem({ id, option: newOption });
  };


  return (
    <Styled.Wrapper>
      <Styled.ContentWrapper>
        <Styled.HeaderWrapper>
          <Styled.Title>CONFIGURE MENU</Styled.Title>
          <Styled.TopButtonWrapper>
            <Styled.BackButton onClick={back} />
          </Styled.TopButtonWrapper>
          <Styled.Subtitle>Dish detail</Styled.Subtitle>
        </Styled.HeaderWrapper>

        <FormikProvider value={formik}>
          <Styled.Form onSubmit={formik.handleSubmit}>
            <Styled.FormFields>
              <Styled.InputContainer>
                <FormInput
                  type="text"
                  text=""
                  name="name"
                  maxLength={100}
                  placeholder="Dish name"
                />
                <TextArea
                  text=""
                  name="description"
                  placeholder="Dish description"/>
                <FormInput
                  type="number"
                  step={0.01}
                  text=""
                  name="price"
                  placeholder="Dish price"
                />
                <Styled.OptionsWrapper>
                  <Styled.OptionsTitle>Additional options</Styled.OptionsTitle>
                  <Styled.OptionsList>
                    {dish && dish.menuItemOptions.length ?
                      dish.menuItemOptions?.map((option) =>
                        <li key={option.id}>{`${option.name} - ${option.price}€`}</li>) :
                      options && options.length ? options?.map((option) =>
                        <li key={option.id}>{`${option.name} - ${option.price}€`}</li>) :
                        'No options provided'}
                  </Styled.OptionsList>
                  <Styled.OptionsButtonWrapper>
                    <Button
                      type="button"
                      rounded={true}
                      background="secondary"
                      onClick={openModal}
                    >{dish && dish.menuItemOptions.length ? 'Edit options' : 'Add options'}</Button>
                  </Styled.OptionsButtonWrapper>
                </Styled.OptionsWrapper>
                <Button
                  rounded={true}
                  background="primary"
                  type="submit"
                >Add dish</Button>
              </Styled.InputContainer>
              <Styled.ImageContainer>
                {loadedIcon ? (
                  <Styled.Preview src={loadedIcon} />
                ) : (
                  <ImageDropzone setFormikMedia={setFormikMedia} setFileData={setLoadedIcon} />
                )}
                <Styled.ClearButtonContainer>
                  <Button
                    type="button"
                    rounded={true}
                    background="secondary"
                    onClick={clearImage}
                  >Clear image</Button>
                </Styled.ClearButtonContainer>
              </Styled.ImageContainer>
            </Styled.FormFields>
          </Styled.Form>
        </FormikProvider>
        <EditOptionsModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          options={dish?.menuItemOptions || options}
          onAddSubmit={dish ? createNewOptionForExistedItem : createNewOptionForNewItem}
          isNew={!dish}
          setOptions={setOptions}
        />
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
};
