/* eslint-disable max-len */
import { MockDB } from '../modules/common/services/mock-http.service';
import { HttpMethods } from '../types';

export default function(baseURL: string) {
  MockDB.connection().addRecord(baseURL + `/api/table`, {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          id: '8770318c-519e-4aae-bdf8-645cf81e3b4c',
          name: 'Table 1',
          nfcCode: {
            id: '596780f7-32ed-4004-946b-0e99d15d63c5',
            createdAt: '2021-10-12T08:25:59.600Z',
            updatedAt: '2021-10-12T08:25:59.600Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijg3NzAzMThjLTUxOWUtNGFhZS1iZGY4LTY0NWNmODFlM2I0YyIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQwMjcxNTl9.MDlBUw3Yu6H_BBxD_PCW1kileBVr14ziRL9Kk27AhO8',
          },
          openOrders: [],
        },
        {
          id: '9d1891ef-0ecd-4d65-a7e8-cff138570372',
          name: 'Table 2',
          nfcCode: {
            id: '45bfa468-b7b7-49d6-a7b2-42416e09d069',
            createdAt: '2021-10-12T08:25:59.671Z',
            updatedAt: '2021-10-12T08:25:59.671Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjlkMTg5MWVmLTBlY2QtNGQ2NS1hN2U4LWNmZjEzODU3MDM3MiIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQwMjcxNTl9.9VJmAcxe2nj9JmFpOZJQ43kE4xHf_-apNtTfqnveuf4',
          },
          openOrders: [],
        },
        {
          id: '1c500681-7b51-412a-991a-49b0f2ad15fc',
          name: 'Table 1',
          nfcCode: {
            id: '879f1a63-0956-4883-bdb9-1167eceb9146',
            createdAt: '2021-10-12T08:44:48.743Z',
            updatedAt: '2021-10-12T08:44:48.743Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFjNTAwNjgxLTdiNTEtNDEyYS05OTFhLTQ5YjBmMmFkMTVmYyIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQwMjgyODh9.uFrEJTvWL3W2iMBte0lKHrYWq0TVHL-6Iev2f99qoL0',
          },
          openOrders: [
            {
              orderId: '7657e98e-0976-4bd5-8c2e-dc569536253b',
              status: 'placed',
            },
            {
              orderId: 'edca8823-3d28-454c-8dea-d91dc3d21f5b',
              status: 'placed',
            },
            {
              orderId: '73c47606-98b8-464b-8af1-6e2f6da04b7f',
              status: 'placed',
            },
            {
              orderId: 'f6413743-9487-436d-9751-de745e3f6cdf',
              status: 'placed',
            },
            {
              orderId: '030a5a57-4475-4ac3-9008-46d3d05cec0f',
              status: 'placed',
            },
            {
              orderId: '2b8a2858-8c91-42ec-a161-845c09171bdb',
              status: 'placed',
            },
            {
              orderId: '260542c6-f731-4829-a01d-3e10af5d7dd7',
              status: 'placed',
            },
            {
              orderId: '808d63c8-4b81-4e11-b2a3-eb1b118c12f0',
              status: 'placed',
            },
            {
              orderId: 'afbffbc5-0343-4289-bc1f-f2b767a4085a',
              status: 'placed',
            },
            {
              orderId: '18e911a4-f0b1-4587-8674-8fce90c5dfb5',
              status: 'placed',
            },
            {
              orderId: '0db9411b-2b3f-4346-abdc-7bb73f2f96c9',
              status: 'accepted',
            },
            {
              orderId: 'c347bb1f-774f-43eb-98c0-88de9a83151a',
              status: 'placed',
            },
            {
              orderId: 'abbbcbd5-4b6a-43d8-9a4a-8f146347230e',
              status: 'placed',
            },
          ],
        },
        {
          id: 'fc437926-2553-428c-ad46-9a22480092c0',
          name: 'Table 2',
          nfcCode: {
            id: '5c3a6c4d-9949-46df-9ac7-47b39e6c6446',
            createdAt: '2021-10-12T08:44:48.835Z',
            updatedAt: '2021-10-12T08:44:48.835Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImZjNDM3OTI2LTI1NTMtNDI4Yy1hZDQ2LTlhMjI0ODAwOTJjMCIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQwMjgyODh9.a5zF3b8N-vGTsSO9MHzDXhtW0k7avqeXDeSmanxu-0k',
          },
          openOrders: [],
        },
        {
          id: '67e32539-86d4-4d3f-9817-9d4b80acd9fb',
          name: 'Table 1',
          nfcCode: {
            id: '09f23484-82d6-4db0-b228-5da58afe0971',
            createdAt: '2021-10-13T14:10:47.335Z',
            updatedAt: '2021-10-13T14:10:47.335Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3ZTMyNTM5LTg2ZDQtNGQzZi05ODE3LTlkNGI4MGFjZDlmYiIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQxMzQyNDd9.C-KHxpU2qeocYFRyFEIHjmLZ2aUhOZDdVH9qbSGT1Sw',
          },
          openOrders: [
            {
              orderId: '69aacade-6278-4b30-92e9-8f7894020b82',
              status: 'placed',
            },
            {
              orderId: '3609a4a5-e53e-4eb5-8b43-bb1505f494fd',
              status: 'placed',
            },
          ],
        },
        {
          id: 'f6327ae9-cc5d-423f-8193-8b0dfc47a595',
          name: 'Table 2',
          nfcCode: {
            id: '1de37207-30df-49d9-80ee-9ab3be9b023d',
            createdAt: '2021-10-13T14:10:47.388Z',
            updatedAt: '2021-10-13T14:10:47.388Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImY2MzI3YWU5LWNjNWQtNDIzZi04MTkzLThiMGRmYzQ3YTU5NSIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQxMzQyNDd9.Zk1opkzaVPV6R80ovpBeGXGY5vL_HMgSF944eolKmIA',
          },
          openOrders: [],
        },
        {
          id: '5940aa68-2764-4f4c-9f72-c9485b6c9d05',
          name: 'Table 1',
          nfcCode: {
            id: '64ef4a71-553c-45d3-9df2-54befdebc0ef',
            createdAt: '2021-10-13T14:13:15.394Z',
            updatedAt: '2021-10-13T14:13:15.394Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjU5NDBhYTY4LTI3NjQtNGY0Yy05ZjcyLWM5NDg1YjZjOWQwNSIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQxMzQzOTV9.LfI3cs33IBHipeWxRq_NA4IL6VIm7XLHSNKJ90R_deE',
          },
          openOrders: [],
        },
        {
          'id': '057460d0-c817-47d5-a9d7-caa3061ef33c',
          'name': 'Table 2',
          'nfcCode': {
            'id': '34861bd9-71a2-4009-b322-6f47e01d6ffe',
            'createdAt': '2021-10-13T14:13:15.441Z',
            'updatedAt': '2021-10-13T14:13:15.441Z',
            'code': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA1NzQ2MGQwLWM4MTctNDdkNS1hOWQ3LWNhYTMwNjFlZjMzYyIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQxMzQzOTV9.5RyzbSeRLl-pcdiMAlpQ2_IdX6Eyv8O_MLQoZiHpirI',
          },
          'openOrders': [],
        },
        {
          id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
          name: 'Table 1',
          nfcCode: {
            id: 'f8282c46-c07b-4a3d-a139-3c058226abc8',
            createdAt: '2021-10-14T13:26:42.829Z',
            updatedAt: '2021-10-14T13:26:42.829Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImQ3ZmI4MzhhLTJlYTUtNGYxYi05Y2M4LWFhYTU1YzY2MmJiZCIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQyMTgwMDJ9.jHgBvU1IQrpS_LrmxtZha4LQA6tjBfU-dV7OTF7jDos',
          },
          openOrders: [
            {
              orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
              status: 'accepted',
            },
            {
              orderId: 'c9b8a821-1af3-42d1-b630-d0b27464bdb7',
              status: 'placed',
            },
            {
              orderId: 'aeaf9079-7228-4871-909a-5f12ea1ee8ca',
              status: 'accepted',
            },
            {
              orderId: 'b75752df-407b-41b0-9ec6-9e4cb9e2ee41',
              status: 'placed',
            },
          ],
        },
        {
          id: 'a93e1e41-a52d-4f53-bbca-b742791e16ec',
          name: 'Table 2',
          nfcCode: {
            id: '1971ea2d-0254-4e2e-9b43-110974207529',
            createdAt: '2021-10-14T13:26:42.997Z',
            updatedAt: '2021-10-14T13:26:42.997Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImE5M2UxZTQxLWE1MmQtNGY1My1iYmNhLWI3NDI3OTFlMTZlYyIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQyMTgwMDJ9.MJE4E7pQIu5LlH9S_GPnbzzwIsti7W33aijV5YM305U',
          },
          openOrders: [],
        },
        {
          id: '09d36402-81c3-4ffd-84bc-653d19171bb7',
          name: 'Table 3',
          nfcCode: {
            id: 'aff7c734-05cd-4e1b-adf0-d14380c50f1d',
            createdAt: '2021-10-14T13:26:43.053Z',
            updatedAt: '2021-10-14T13:26:43.053Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA5ZDM2NDAyLTgxYzMtNGZmZC04NGJjLTY1M2QxOTE3MWJiNyIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQyMTgwMDN9.ecYHOYwuadcJOYesLuA3Ewz1eHlpwdfzafidaGY8tak',
          },
          openOrders: [],
        },
        {
          id: '4463572c-5f5c-41f5-b0fa-fd6688e21993',
          name: 'Table 4',
          nfcCode: {
            id: 'f05ad874-8d19-4f11-a276-69d491e18b56',
            createdAt: '2021-10-14T13:26:43.280Z',
            updatedAt: '2021-10-14T13:26:43.280Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjQ0NjM1NzJjLTVmNWMtNDFmNS1iMGZhLWZkNjY4OGUyMTk5MyIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQyMTgwMDN9.NzDQlKjPvTjcRILIrGfKJDxZVbSdLf-H_eY82ji8hfg',
          },
          openOrders: [],
        },
        {
          id: '9ae75823-e16b-43e1-a96a-1592f705c047',
          name: 'Table 5',
          nfcCode: {
            id: 'a27ef963-32eb-4a8b-8f10-ae73f2cd1a07',
            createdAt: '2021-10-14T13:26:43.327Z',
            updatedAt: '2021-10-14T13:26:43.327Z',
            code: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjlhZTc1ODIzLWUxNmItNDNlMS1hOTZhLTE1OTJmNzA1YzA0NyIsInJvbGUiOlsidmlzaXRvciJdLCJpYXQiOjE2MzQyMTgwMDN9.fRDE4H31RTjzDNpsytqODd-oiZMi6ZyDeHApH9nlKng',
          },
          openOrders: [
            {
              orderId: '69aacade-6278-4b30-92e9-8f7894020b82',
              status: 'placed',
            },
            {
              orderId: '3609a4a5-e53e-4eb5-8b43-bb1505f494fd',
              status: 'placed',
            },
          ],
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
}
