import styled from 'styled-components';

const TableNumber = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.md};
  position: relative;
`;

const Status = styled.div`
  word-break: break-all;
  text-align: center;
  margin-left: ${({ theme }) => theme.spaces.xl};
  padding: ${({ theme }) => theme.spaces.sm};

  @media (max-width: 991px) {
    left: 20px;
  }
  @media (max-width: 800px) {
    left: 15px;
  }
`;

const IndicatorContainer = styled.div`
  width: 35px;
  display: flex;
  justify-content: center;
  left: 25px;
  top: calc(50% - 14px);
  position: absolute;
  @media (max-width: 991px) {
    left: 20px;
  }
  @media (max-width: 800px) {
    left: 15px;
  }
`;

const AcceptOrderButton = styled.button`
  font-size: ${({ theme }) => theme.fonts.plusButton};
  font-weight: 500;
  line-height: 1;
  color: ${({ theme }) => theme.colors.white};
  width: 35px;
  height: 30px;
  background: ${({ theme }) => theme.colors.yellow};
  border: none;
  &:hover{
    cursor: pointer;
  }
`;


export const Styled = {
  TableNumber,
  AcceptOrderButton,
  IndicatorContainer,
  Status,
};
