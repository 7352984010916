export interface ICategory {
  id: string;
  media: string;
  type: string;
  createdAt?: string;
  menuItems: IDish[];
}

export interface IDish {
  id: string;
  media: string;
  name: string;
  description: string;
  available: boolean;
  price: number;
  menu?: ICategory;
  menuItemOptions: IDishOption[],
  createdAt: string;
  updatedAt: string;
}

export interface IDishOption {
  name: string,
  price: number,
  id: string,
  createdAt?: string,
}

export interface IDishOptionWithoutId {
  name: string,
  price: number,
}

export interface IDishPayload {
  id?: string,
  media: string;
  name: string;
  description: string;
  available: boolean;
  price: number;
  menuItemOptions?: Partial<IDishOption>[] | null
}

export interface ICategoryPayload {
  media: string;
  type: string;
}

export interface IIdMutationPayload {
  id: string;
}

export interface IMenuItemOptionMutationPayload extends IIdMutationPayload{
  option: IDishOptionWithoutId
}

export enum ModalTypes {
  Create,
  Edit,
  None,
}
