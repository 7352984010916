import styled from 'styled-components';

const Wrapper = styled.div`
  padding-left: ${({ theme }) => theme.spaces.lg};
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  width: 150px;
  margin-right: ${({ theme }) => theme.spaces.md};
`;

export const Styled = {
  Wrapper,
  ButtonWrapper,
};
