import { ICategoryPayload, IDish } from './../types/index';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpService } from '../../common/services/http.service';
import { HttpServiceFactory } from '../../common/services';
import { ROUTES } from '../../common/constants';
import { ICategory } from './../types';
import { EnhancedWithAuthHttpService } from '../../common/services/http-auth.service';

export class MenuService {
  private httpService: EnhancedWithAuthHttpService;

  constructor(private httpFactory: HttpServiceFactory) {
    this.httpService = this.httpFactory.createAuthHttpService();
  }

  public getMenu(venueId: string): Promise<ICategory[] | void> {
    return this.httpService.get<ICategory[]>(ROUTES.MENU, {
      params: { venueId },
      url: ROUTES.MENU,
    });
  }

  public addMenu(newMenu: ICategoryPayload): Promise<ICategory | void> {
    return this.httpService.post<ICategory, ICategoryPayload>(`${ROUTES.MENU}`, newMenu);
  }

  public editMenu(newMenu: ICategoryPayload, id: string): Promise<ICategory | void> {
    return this.httpService.put<ICategory, ICategoryPayload>(`${ROUTES.MENU}/${id}`, newMenu);
  }

  public deleteMenu(menuId: string): Promise<ICategory | void> {
    return this.httpService.delete<ICategory>(`${ROUTES.MENU}/${menuId}`);
  }

  public getMenuItem(id: string): Promise<IDish | void> {
    return this.httpService.get<IDish>(`${ROUTES.MENU_ITEM}/${id}`);
  }

  public addMenuItem(dish: IDish): Promise<IDish | void> {
    return this.httpService.post<IDish, IDish>(`${ROUTES.MENU_ITEM}`, dish);
  }

  public editMenuItem(id: string, dish: IDish): Promise<IDish | void> {
    return this.httpService.put<IDish, IDish>(`${ROUTES.MENU_ITEM}/${id}`, dish);
  }

  public deleteMenuItem(id: string): Promise<IDish | void> {
    return this.httpService.delete(`${ROUTES.MENU_ITEM}/${id}`);
  }
}

export const menuService = new MenuService(new HttpServiceFactory());
