import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { ordersService } from '../../services/orders.service';
import { InnerTitle } from '../inner-title';
import { Table } from '../../../common/components/table';
import { TableNumberCell } from '../order-table-cells/table-number-cell';
import { OrderStatusCell } from '../order-table-cells/order-status-cell';
import { ButtonCell } from '../order-table-cells/button-cell';
import { CreatedAtCell } from '../order-table-cells/text-cell';
import { ITableColumn, TableCellType } from '../../../common/types';
import { sortOrdersDataByKeyValue } from '../../utils/sort-orders';
import { IOrder } from '../../types';

export const OrdersHistory:FC = () => {
  const columns:ITableColumn[] = [
    {
      title: 'Order ID',
      key: 'table',
      type: TableCellType.TableNumber,
      Component: TableNumberCell,
    },
    {
      title: 'Order time',
      key: 'createdAt',
      type: TableCellType.CreatedAt,
      Component: CreatedAtCell,
    },
    {
      title: 'Order status',
      key: 'status',
      type: TableCellType.OrderStatus,
      Component: OrderStatusCell,
    },
    {
      title: 'View order detail',
      key: 'view',
      type: TableCellType.Button,
      Component: ButtonCell,
    },
  ];
  const fetchCompletedOrders = () => {
    return ordersService.fetchCompletedOrders();
  };

  const { data, isLoading } = useQuery('completedOrders', fetchCompletedOrders);
  const sortedData = sortOrdersDataByKeyValue(data?.order as IOrder[], 'numberId', 'DESC');

  return (
    <>
      <InnerTitle>Past Orders</InnerTitle>
      {
        isLoading ? 'Loading...' : (
          <Table
            columns={columns}
            data={sortedData || null}
          />
        )
      }
    </>
  );
};
