import styled from 'styled-components';

const TableRow = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spaces.xxs};
`;

export const Styled = {
  TableRow,
};
