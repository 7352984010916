export enum storeKeys {
  token = 'TOKEN',
  role = 'ROLE',
  profile = 'PROFILE'
};

export enum userRoles {
  waiter = 'waiter',
  owner = 'venueOwner',
  admin = 'superAdmin',
}

export enum ROUTES {
  MENU = 'menu',
  MENU_ITEM = 'menu-item',
  ROUTES_MENU_ITEM_OPTION = 'menu-item-option',
  PROFILE = 'me',
};

export const roleNames = {
  [userRoles.owner]: 'Owner',
  [userRoles.admin]: 'Admin',
  [userRoles.waiter]: 'Waiter',
};

export enum socketEvents {
  getOrders = 'order',
  requestBill = 'requestBill',
  changeOrder = 'changeOrder',
  callWaiter = 'callWaiter',
  subscribeOrder = 'subscribeOrder',
}

export enum modalTypes {
  requestBill = 'requestBill',
  callWaiter = 'callWaiter',
  orderUpdate = 'orderUpdate',
  none = 'none',
}

export enum Alignment {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export enum ErrorMessage {
  DEFAULT = 'Oops, something went wrong!',
}
