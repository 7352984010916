import React, { FC } from 'react';
import { ITab } from '../../types';
import { IOrdersTabsProps } from '../order-tabs';
import { Styled } from './order-tab.styled';

interface IOrderTabProps extends ITab, IOrdersTabsProps{

}

export const OrderTab:FC<IOrderTabProps> = ({ setCurrentTab, currentTab, title, slug }) => {
  const onOrdersTabClickHandler = () => {
    setCurrentTab(slug);
  };

  return (
    <Styled.Tab
      onClick={onOrdersTabClickHandler}
      isActive={currentTab === slug}
    >
      {title}
    </Styled.Tab>
  );
};
