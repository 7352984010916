import { useMutation, useQueryClient } from 'react-query';
import { ordersService } from '../services/orders.service';
import { IChangeOrderStatusPayload } from '../types';


export const useChangeOrderItemStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(( { id, status }:IChangeOrderStatusPayload) =>
    ordersService.changeOrderItemStatus(id, status), {
    onSuccess: () => {
      queryClient.invalidateQueries('order');
    },
  });
};
