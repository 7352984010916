import React, { FC } from 'react';
import { Select } from '../../../../common/components/select';
import { Styled } from './order-dish.styled';
import { converseToFixed } from '../../../utils/amount-to-fixed';
import { IOrderItemOption, IOrderItemStatus, IStatus } from '../../../types';
import { IOption } from '../../../../common/types';

interface IDishProps{
  id?: string,
  name: string,
  amount: number,
  price: number,
  status: IOrderItemStatus,
  comments: string[],
  ingredients: IOrderItemOption[],
  orderItemStatus: IStatus
  onChangeStatus: Function,
}

export const OrderDish:FC<IDishProps> = ({
  ingredients,
  orderItemStatus,
  name,
  amount,
  price,
  status,
  comments,
  onChangeStatus }) => {
  const options:IOption[] = [
    {
      name: 'delivered',
      current: status === 'delivered',
      onClick: () => onChangeStatus('delivered'),
      indicator: 'green',
    },
    {
      name: 'In progress',
      current: status === 'inProgress',
      onClick: () => onChangeStatus('inProgress'),
      indicator: 'yellow',
    },
  ];

  return (
    <Styled.OrderItemWrapper>
      <Styled.OrderDish>
        <Styled.Title>{name}</Styled.Title>
        <Styled.Amount>х {amount}</Styled.Amount>
        <Styled.Status>
          {orderItemStatus === 'completed' ? (
          <p>delivered</p>
        ) : (
          <Select
            options={options}/>
        )}
        </Styled.Status>
        <Styled.Price>{converseToFixed(price)} $</Styled.Price>
      </Styled.OrderDish>
      {comments.length ? (
        <Styled.Comments>
          <strong>Client&apos;s wishes/comments:</strong>
          {comments.map((item) => <li key={item}>{item}</li>)}
        </Styled.Comments>) : null}
      {ingredients.length ? (
        <Styled.Comments>
          <strong>Additional options:</strong>
          {ingredients.map((item) => <li style={{ justifyContent: 'space-between' }} key={item.id}>
            <span>{item.name}</span>
            <span style={{ float: 'right', paddingRight: 10 }}>{item.price}$</span></li>)}
        </Styled.Comments>) : null}
    </Styled.OrderItemWrapper>
  );
};
