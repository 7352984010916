import React, { useEffect, useState } from 'react';
import ReorderIcon from '../../../../assets/reorder.png';
import { Styled } from './dish.styled';
import IOSSwitch from '../../../common/components/switch';
import Menu from '../../../common/components/actions-menu';
import { useHistory } from 'react-router-dom';
import { ICategory, ICategoryPayload } from '../../types';
import { useMutation, useQueryClient } from 'react-query';
import { menuService } from '../../services/menu.service';

interface IDishProps {
  id: string;
  media: string;
  name: string;
  description: string;
  price: number;
  available: boolean;
  category: ICategory;
  onDelete: (id: string) => void;
  venueId: string;
  onMenuItemEdit: (id: string, category: ICategoryPayload) => void;
}

const Dish: React.FC<IDishProps> = ({
  id,
  media,
  name,
  description,
  available,
  onDelete,
  category,
  price,
}) => {
  const [isSelected, setIsSelected] = useState(available);
  const history = useHistory();
  const queryClient = useQueryClient();

  useEffect(() => {
    setIsSelected(available);
  }, [available]);

  const editItem = () => {
    history.push(`/menu/menu-item/${id}`);
  };

  const deleteItem = () => {
    onDelete(id);
  };
  const editMenuItemMutation = useMutation(( { dish }:any) =>
    menuService.editMenuItem( id, dish ), {
    onSuccess: () => {
      queryClient.invalidateQueries('menu');
    },
  });
  const toggleIsAvailable = () => {
    const dish = {
      name,
      description,
      price: price,
      available: !available,
      menu: {
        id: category.id,
      },
      media,
    };
    editMenuItemMutation.mutate({ dish });
  };

  return (
    <Styled.Content>
      <Styled.Wrapper>
        <Styled.ReorderIcon src={ReorderIcon} />
        <Styled.IconWrapper>
          {!!media ? <Styled.Icon src={media} /> : <Styled.BasicIcon />}
        </Styled.IconWrapper>
        <Styled.Dish>
          {name}
        </Styled.Dish>
        <Styled.Ingridients>
          {description}
        </Styled.Ingridients>
      </Styled.Wrapper>
      <Styled.ToolsWrapper>
        <IOSSwitch checked={isSelected} onChange={toggleIsAvailable} />
        <Menu
          onEdit={editItem}
          onDelete={deleteItem}
        />
      </Styled.ToolsWrapper>
    </Styled.Content>
  );
};

export default Dish;
