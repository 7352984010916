import styled from 'styled-components';

const InnerTitle = styled.h2`
  font-size: ${({ theme }) => theme.fonts.xl};
  margin-bottom: ${({ theme }) => theme.spaces.md};
  margin-left: ${({ theme }) => theme.spaces.lg};
  font-weight: 500;
`;
export const Styled = {
  InnerTitle,
};
