import React, { FC, useEffect } from 'react';
import { IDishes, IOrderItem, IOrderItemStatus, IStatus } from '../../../types';
import { useChangeOrderItemStatus } from '../../../utils/change-order-item-status';
import { getDishComments } from '../../../utils/get-dish-comments';
import { OrderDish } from '../order-dish';
import { Styled } from './order-dishes.styled';

interface IOrderDishesProps {
  orderStatus: IStatus
  items: IOrderItem[],
  setIsButtonDisabled: (isButtonDisabled: boolean) => void,
}

export const OrderDishes:FC<IOrderDishesProps> = ({ orderStatus, items, setIsButtonDisabled }) => {
  const dishesWithOptions: IOrderItem[] = [];
  const dishes = items.reduce((object:IDishes, item:IOrderItem) => {
    if (item.orderItemOptions.length) {
      dishesWithOptions.push(item);
      return object;
    }

    if (!(item.menuItem.id in object)) {
      object[item.menuItem.id] = [item];
      return object;
    } else {
      object[item.menuItem.id].push(item);
      return object;
    }
  }, {});

  const checkDishStatuses = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_, dish] of Object.entries(dishes)) {
      if (dish.find((dishItem) => dishItem.status === 'inProgress' )) {
        setIsButtonDisabled(true);
        return;
      }
      setIsButtonDisabled(false);
    }
    for (const dish of dishesWithOptions) {
      if (dish.status === 'inProgress' ) {
        setIsButtonDisabled(true);
        return;
      }
      setIsButtonDisabled(false);
    }
  };
  const ChangeOrderItemStatus = useChangeOrderItemStatus();


  const OrderDishes = Object.keys(dishes).map((dish:string) => {
    const comments = getDishComments(dishes[dish]);
    const checkGeneralItemStatuses = () => {
      if (dishes[dish].find((dishItem) => dishItem.status === 'inProgress' )) {
        return 'inProgress'
        ;
      }
      return 'delivered';
    };

    const onOptionSimpleItemClickHandler = (status: IOrderItemStatus) => {
      dishes[dish].forEach((dish: IOrderItem) => {
        ChangeOrderItemStatus.mutate({ id: dish.id, status });
      });
    };

    return (
      <OrderDish
        key={dish}
        name={dishes[dish][0].menuItem.name}
        amount={dishes[dish].length}
        price={dishes[dish].length * dishes[dish][0].menuItem.price}
        status={checkGeneralItemStatuses()}
        orderItemStatus={orderStatus}
        comments={comments}
        ingredients={[]}
        onChangeStatus={onOptionSimpleItemClickHandler}
      />
    );
  });

  const OrderDishesWithOptions = dishesWithOptions.map((dish:IOrderItem) => {
    const onOptionOptionalItemClickHandler = (status: IOrderItemStatus) => {
      ChangeOrderItemStatus.mutate({ id: dish.id, status });
    };

    return (
      <OrderDish
        key={dish.id}
        name={dish.menuItem.name}
        amount={1}
        price={dish.amount}
        status={dish.status}
        orderItemStatus={orderStatus}
        comments={dish.comment ? [dish.comment] : []}
        ingredients={dish.orderItemOptions}
        onChangeStatus={onOptionOptionalItemClickHandler}
      />
    );
  });

  useEffect(() => {
    checkDishStatuses();
  });

  return (
    <Styled.OrderDishes>
      {OrderDishesWithOptions}
      {OrderDishes}
    </Styled.OrderDishes>
  );
};
