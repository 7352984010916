import React, { FC } from 'react';
import { Indicator } from '../../../../common/components/indicator';
import { auth } from '../../../../common/services/auth.service';
import { ICell } from '../../../../common/types';
import { IStatus } from '../../../types';
import { useChangeStatus } from '../../../utils/change-status';
import { Styled } from './table-number-cell.styled';

export const TableNumberCell:FC<ICell> = ({ item }) => {
  const changeStatus = useChangeStatus();
  const status: IStatus = item.status.name;
  const table = item.table.name;
  const id = item.id;
  const numberId = item.numberId;

  const onAcceptHandler = () => {
    changeStatus.mutate({ id, status: 'accepted' });
  };

  const buttons = auth.isWaiter() ? {
    'placed': (<Styled.AcceptOrderButton
      onClick={onAcceptHandler}
    >+</Styled.AcceptOrderButton>),
    'accepted': (<Indicator color="red"/>),
    'completed': (<Indicator color="green"/>),
    'deleted': (<Indicator color="red"/>),
  } : {
    'placed': (<Indicator color="yellow"/>),
    'accepted': (<Indicator color="red"/>),
    'completed': (<Indicator color="green"/>),
    'deleted': (<Indicator color="red"/>),
  };

  return (
    <Styled.TableNumber>
      <Styled.IndicatorContainer>
        {buttons[status]}
      </Styled.IndicatorContainer>
      <Styled.Status>
        {status !== 'completed' ? table : numberId}
      </Styled.Status>
    </Styled.TableNumber>
  );
};
