// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { ITokenDecode } from '../../../types';
import { storeKeys, userRoles } from '../constants';

export class AuthService {
  constructor() {};
  public isAuthorized():boolean {
    return this.checkIsTokenValid();
  }

  public checkIsTokenValid() {
    const token = localStorage.getItem(storeKeys.token);
    if (!token) {
      return false;
    }

    if (token && !token?.includes('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9')) {
      localStorage.removeItem(storeKeys.profile);
      this.logout();
      return false;
    }

    const tokenDecode = jwt_decode<ITokenDecode>(token as string);
    if (tokenDecode.exp * 1000 > Date.now()) {
      return true;
    } else {
      this.logout();
      return false;
    }
  }

  public logout():void {
    localStorage.removeItem(storeKeys.token);
    localStorage.removeItem(storeKeys.role);
    localStorage.removeItem(storeKeys.profile);
    window.location.href = '/login';
  };

  public setLocalItem = (key: string, data: string) => {
    localStorage.setItem(key, data);
  };

  public getLocalItem = (key: string) => {
    return localStorage.getItem(key);
  };

  public getRole() {
    return this.isAuthorized() ? JSON.parse(this.getLocalItem(storeKeys.role) as string) : null;
  }

  public getProfile() {
    return this.isAuthorized() ? JSON.parse(this.getLocalItem(storeKeys.profile) as string) : null;
  }

  public isOwner() {
    const profile = this.getProfile();
    return profile?.role[0] === userRoles.owner;
  }

  public isWaiter() {
    const profile = this.getProfile();
    return profile?.role[0] === userRoles.waiter;
  }

  public isAdmin() {
    const profile = this.getProfile();
    return profile?.role[0] === userRoles.admin;
  }
};

export const auth = new AuthService();
