import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Styled } from './login-form.styled';
import { FormInput } from '../../../common/components/form-input';
import { Button } from '../../../common/components/button';
import { ownerLoginValidationSchema, waiterLoginValidationSchema } from '../../utils/validation-schema';
import { ErrorBox } from '../../../common/components/error-box';
import { storeKeys, userRoles } from '../../../common/constants';
import { WaiterService } from '../../services/waiter-login.service';
import { auth } from '../../../common/services/auth.service';
import { OwnerService } from '../../services/owner-login.service';
import { ILoginData } from '../../types';
import { initialValues } from '../../constants';

interface LoginFormProps{
  role: userRoles;
  onSumbitTarget: OwnerService | WaiterService;
  setIsAuth: Function;
};

export const LoginForm = ({ role, onSumbitTarget, setIsAuth } : LoginFormProps) => {
  const history = useHistory();
  const [errorAccess, setErrorAccess] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async ({ email, password }: ILoginData) => {
      try {
        const result = await onSumbitTarget.loginUser(email, password);
        if (result?.token) {
          auth.setLocalItem(storeKeys.token, result.token);
          auth.setLocalItem(storeKeys.role, role);
          setIsAuth(true);
          history.push('/');
        }
      } catch (err) {
        setErrorAccess(true);
      }
      setErrorAccess(false);
    },
    validationSchema: role === userRoles.owner ? ownerLoginValidationSchema : waiterLoginValidationSchema,
  });

  return (
    <>
      <FormikProvider value={formik}>
        <Styled.Form onSubmit={formik.handleSubmit}>
          <Styled.InputGroup>
            <FormInput
              placeholder="E-mail"
              name="email"
              type="email"/>
            <FormInput
              placeholder="Password"
              name="password"
              type="password"/>
          </Styled.InputGroup>
          <Styled.ButtonWrapper>
            <Button
              type="submit"
              background="primary"
              rounded={true}
            >Sign in</Button>
          </Styled.ButtonWrapper>
          {errorAccess && (
            <ErrorBox>
              {'Incorrect email or password, please try again'}
            </ErrorBox>
          )}
        </Styled.Form>
      </FormikProvider>
    </>
  );
};
