import React, { FC } from 'react';
import { Styled } from './form-input.styled';
import { getIn, useFormikContext } from 'formik';
import { ErrorBox } from '../error-box';

export interface InputProps {
  text?: string;
  name: string;
  type: string;
  placeholder?: string;
  step?: number;
  width?: string;
  min?: number;
  isEdit?: boolean,
  maxLength?:number,
};

export const FormInput: FC<InputProps> = ({
  text,
  name,
  type,
  min,
  placeholder,
  step,
  width,
  maxLength,
  isEdit,
}) => {
  const { values, handleChange, errors, touched } = useFormikContext();
  const isErrorExists = getIn(errors, name) && getIn(touched, name);

  return (
    <Styled.InputWrapper isEdit={isEdit}>
      {text && <Styled.Label htmlFor={name}>{text}</Styled.Label>}
      <Styled.Input
        id={name}
        name={name}
        type={type}
        step={step}
        maxLength={maxLength}
        min={min}
        placeholder={placeholder}
        onChange={handleChange(name)}
        value={getIn(values, name)}
        width={width}
      />
      {isErrorExists && (
        <ErrorBox isEdit={isEdit} isErrorExists={isErrorExists}>
          {getIn(errors, name)}
        </ErrorBox>
      )}
    </Styled.InputWrapper>
  );
};
