import styled from 'styled-components';

const TextCell = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.md};
`;

export const Styled = {
  TextCell,
};
