import { MockDB } from '../modules/common/services/mock-http.service';
import { HttpMethods } from '../types';

export default function(baseURL: string) {
  MockDB.connection().addRecord(baseURL + '/api/waiter-auth/login', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        token: 'eyJhbGciOiJs',
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        token: 'eyJhbGciOiJs',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL + '/api/waiter-auth/registration', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        test: 'get',
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        email: 'ow4@gmail.com',
        name: 'Mary 3',
        venue: {
          id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          createdAt: '2021-10-14T13:26:42.697Z',
          updatedAt: '2021-10-14T13:26:42.697Z',
          name: 'Venue 1',
          metadataId: '',
        },
        id: '0f49e0a3-93cb-4e6c-8a91-6febd1e6279a',
        createdAt: '2021-10-15T14:24:56.609Z',
        updatedAt: '2021-10-15T14:24:56.609Z',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL + '/api/waiters/', {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          id: '659f0a6b-6092-4b3a-98dd-d69eb10c0a5d',
          createdAt: '2021-10-14T09:23:39.916Z',
          updatedAt: '2021-10-14T09:23:39.916Z',
          email: 'q5@gmail.com',
          name: 'Jack',
        },
        {
          id: '8a64f0d5-ac22-4e6d-8327-4724b5829560',
          createdAt: '2021-10-14T09:23:44.061Z',
          updatedAt: '2021-10-14T09:23:44.061Z',
          email: 'q6@gmail.com',
          name: 'Pete',
        },
        {
          id: '8dedf4a6-0d83-41e4-b8bb-00c7cb029a86',
          createdAt: '2021-10-14T09:36:09.538Z',
          updatedAt: '2021-10-14T09:36:09.538Z',
          email: 'q7@gmail.com',
          name: 'Mary',
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL + '/api/waiter/659f0a6b-6092-4b3a-98dd-d69eb10c0a5d', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        email: 'ow4@gmail.com',
        name: 'Mary 3',
        venue: {
          id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          createdAt: '2021-10-14T13:26:42.697Z',
          updatedAt: '2021-10-14T13:26:42.697Z',
          name: 'Venue 1',
          metadataId: '',
        },
        id: '0f49e0a3-93cb-4e6c-8a91-6febd1e6279a',
        createdAt: '2021-10-15T14:24:56.609Z',
        updatedAt: '2021-10-15T14:24:56.609Z',
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        email: 'ow4@gmail.com',
        name: 'Mary 4',
        venue: {
          id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
          createdAt: '2021-10-14T13:26:42.697Z',
          updatedAt: '2021-10-14T13:26:42.697Z',
          name: 'Venue 1',
          metadataId: '',
        },
        id: '0f49e0a3-93cb-4e6c-8a91-6febd1e6279a',
        createdAt: '2021-10-15T14:24:56.609Z',
        updatedAt: '2021-10-15T14:24:56.609Z',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        id: 'e5a28441-5d58-47d1-9c36-7a2b1cd14486',
      },
    },
  });
}
