import { IOrderItem } from '../types';

export const getDishComments = (dish :IOrderItem[]) => {
  return dish?.reduce((comments: string[], item) => {
    if (item.comment.length && !comments?.includes(item.comment)) {
      comments?.push(item.comment);
    }
    return comments;
  }, []);
};
