import React, { FC } from 'react';
import { ICell } from '../../../../common/types';
import { Styled } from './table-button.styled';

export const TableButton:FC<ICell> = ({ item, onClick = () => {} }) => {
  const onClickHandler = () => {
    onClick(item);
  };

  return (
    <Styled.ButtonCell>
      <Styled.EditButton
        onClick={onClickHandler}
      >
          Edit
      </Styled.EditButton>
    </Styled.ButtonCell>
  );
};
