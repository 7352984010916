import styled from 'styled-components';

const Card = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  flex-basis: 22%;
  min-width: 160px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spaces.md};
  margin-right: ${({ theme }) => theme.spaces.sm};
  padding: ${({ theme }) => theme.spaces.md} ${({ theme }) => theme.spaces.xs};
  @media(max-width: 800px){
    flex-basis: 44%;
  }
`;

const EditButton = styled.img`
  width: 14px;
  height: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover{
    cursor: pointer;
  }
`;

const WaiterAvatar = styled.img`
  margin-bottom: ${({ theme }) => theme.spaces.xs};
  width: 68px;
  height: 68px;
`;

const WaitersName = styled.h3`
  font-size: ${({ theme }) => theme.fonts.md};
  margin-bottom: ${({ theme }) => theme.spaces.xs};
  color:  ${({ theme }) => theme.colors.secondary};
  word-break: break-all;
  font-weight: 400;
  text-align: center;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InfoRow = styled.span`
  font-size: ${({ theme }) => theme.fonts.sm};
  text-align: center;
  word-break: break-word;
`;

export const Styled = {
  Card,
  EditButton,
  WaiterAvatar,
  WaitersName,
  InfoBox,
  InfoRow,
};
