import styled from 'styled-components';

const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.fonts.xl};
  margin: 0 0 ${({ theme }) => theme.spaces.lg};
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 332px;
`;
export const Styled = {
  Subtitle,
  ButtonContainer,
};
