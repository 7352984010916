import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.spaces.xxl});
  margin: 0 auto;
`;

const Status = styled.h1`
  font-size: 40px;
  margin: 0;
`;

export const Styled = {
  Wrapper,
  Status,
};
