import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ArrowBack } from '../../../common/components/arrow-back';
import { PageTitle } from '../../../common/components/page-title';
import { socketEvents } from '../../../common/constants';
import { socketService } from '../../../common/services/socket-service';
import { IOrder, IOrderParams } from '../../types';
import { OrderCard } from './order-card';
import { Styled } from './order-detail.styled';

export const OrderDetail:FC = () => {
  const [order, setOrder] = useState<IOrder | null>(null);
  const history = useHistory();
  const { id } = useParams<IOrderParams>();

  const onArrowBackClickHandler = () => {
    history.push('/orders');
  };

  const onSubscribeOrder = (order: IOrder) => {
    if (order.status.name === 'deleted') {
      history.push('/orders');
    } else {
      setOrder(order);
    }
  };


  useEffect(() => {
    socketService.socket?.emit('subscribe-order', { id });
    socketService.socket?.on(socketEvents.subscribeOrder, onSubscribeOrder);

    return () => {
      socketService.socket?.off(socketEvents.subscribeOrder, onSubscribeOrder);
      socketService.socket?.emit('unsubscribe-order', { id });
    };
  }, []);


  return (
    <Styled.Wrapper>
      <PageTitle>ORDER DETAIL</PageTitle>
      <ArrowBack
        onClick={onArrowBackClickHandler} />
      <OrderCard
        order={order || null} />
    </Styled.Wrapper>
  );
};
