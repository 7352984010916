import React, { FC } from 'react';
import { Styled } from './error-box.styled';

interface IErrorProps {
  children: string;
  isEdit?: boolean;
  isErrorExists?: string;
}

export const ErrorBox: FC<IErrorProps> = ({ children, isEdit, isErrorExists }) => {
  return (
    <Styled.ErrorBox isEdit={isEdit} isErrorExists={isErrorExists}>
      {children}
    </Styled.ErrorBox>
  );
};
