import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import Modal from 'react-modal';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../../../common/components/button';
import { FormInput } from '../../../common/components/form-input';
import { menuItemOptionService } from '../../services/menu-item-option.service';
import { IDishOption, IIdMutationPayload, IMenuItemOptionMutationPayload } from '../../types';
import { optionValidationSchema } from '../../utils/option-validation';
import { sortOrderByDate } from '../../utils/sortArray';
import { EditModalItem } from './edit-modal-item/edit-modal-item.component';
import { Styled } from './options-modal.styled';

interface IEditOptionsModalProps{
  isOpen: boolean,
  closeModal: () => void
  options: IDishOption[] | IDishOption[],
  onAddSubmit: Function
  isNew: boolean
  setOptions: React.Dispatch<React.SetStateAction<IDishOption[]>>,
}

export const EditOptionsModal:FC<IEditOptionsModalProps> = ({
  closeModal,
  isOpen,
  options,
  onAddSubmit,
  isNew,
  setOptions }) => {
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: {
      optionName: '',
      optionPrice: 0,
    },
    onSubmit: ({ optionName, optionPrice }) => {
      onAddSubmit(optionName, optionPrice);
    },
    validationSchema: optionValidationSchema,
  });

  const { mutate: deleteItem } = useMutation(( { id }:any) =>
    menuItemOptionService.deleteMenuItemOption(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('menu-item');
    },
  });

  const { mutate: editItem } = useMutation(( { id, option }: IMenuItemOptionMutationPayload) =>
    menuItemOptionService.editMenuItemOption(id, option), {
    onSuccess: () => {
      queryClient.invalidateQueries('menu-item');
    },
  });

  const deleteNewItem = ({ id }: IIdMutationPayload) => {
    setOptions((prev) => prev?.filter((option) => option.id !== id));
  };

  const editNewItem = ({ id, option }: IMenuItemOptionMutationPayload) => {
    setOptions((prev) => {
      const newOpt = [...prev];
      const index = newOpt.findIndex((opt) => opt.id === id);
      newOpt[index].name = option.name;
      newOpt[index].price = option.price;
      return newOpt;
    });
  };

  const sortByCreationDate = () => sortOrderByDate(options as IDishOption[], 'createdAt', 'ASC');

  const sortedOptions = sortByCreationDate();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      // @ts-ignore
      style={Styled.modalStyles}>
      <Styled.RoundButton onClick={closeModal}>X</Styled.RoundButton>
      <Styled.ModalTitle>Edit options</Styled.ModalTitle>
      <Styled.OptionsWrapper>
        {options && options.length ? sortedOptions.map((option) => (
          <EditModalItem
            key={option?.id}
            option={option}
            onEdit={isNew ? editNewItem : editItem}
            onDelete={isNew ? deleteNewItem : deleteItem}
          />
        )) : 'No options provided'}
      </Styled.OptionsWrapper>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Styled.ModalTitle>Options form</Styled.ModalTitle>
          <FormInput
            type="text"
            name="optionName"
            placeholder="Option name"
            isEdit={false}
          />
          <FormInput
            type="number"
            step={0.01}
            min={0}
            name="optionPrice"
            placeholder="Option price"
            isEdit={false}
          />
          <Button rounded={true} background="primary" type="submit">
            Add option
          </Button>
        </form>
      </FormikProvider>
    </Modal>
  );
};
