import React, { FC } from 'react';
import { IMap } from '../../../../types';
import { ITableColumn } from '../../types';
import { Styled } from './table-row.styled';

interface ITableRowProps {
  item: IMap,
  columns: ITableColumn[],
}

export const TableRow:FC<ITableRowProps> = ({ columns, item }) => {
  const elements = columns.map(({ onClick, Component, key }) => {
    return (<Component key={`${key}_${item.id}`} item={item} onClick={onClick}/>);
  });

  return (
    <Styled.TableRow>
      {elements}
    </Styled.TableRow>
  );
};
