import styled from 'styled-components';

const modalStyles = {
  content: {
    top: '15%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '768px',
    maxHeight: '85%',
    overflowY: 'auto',
    transform: 'translate(-50%, -10%)',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(128,128,128, 0.5)',
  },
};

const RoundButton = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: flex-end;
  position: absolute;
  right: 10px;
  border: none;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spaces.sm};
`;

const OptionsWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.sm};
`;

export const Styled = {
  modalStyles,
  RoundButton,
  ModalTitle,
  OptionsWrapper,
};
