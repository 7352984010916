import React, { FC } from 'react';
import { Styled } from './textarea.styled';
import { getIn, useFormikContext } from 'formik';
import { ErrorBox } from '../error-box';

export interface ITextareaProps {
  text?: string;
  name: string;
  placeholder: string;
};

export const TextArea:FC<ITextareaProps> = ({ text, name, placeholder }) => {
  const { values, handleChange, errors, touched } = useFormikContext();
  const isErrorExists = getIn(errors, name) && getIn(touched, name);

  return (
    <Styled.TextareaWrapper>
      { text && (<Styled.Label htmlFor={name}>{text}</Styled.Label>) }
      <Styled.Textarea
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={handleChange(name)}
        value={getIn(values, name)}
      />
      {isErrorExists && (
        <ErrorBox>{ getIn(errors, name) }</ErrorBox>
      )}
    </Styled.TextareaWrapper>
  );
};
