import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { ordersService } from '../../services/orders.service';
import { InnerTitle } from '../inner-title';
import { Table } from '../../../common/components/table';
import { TableNumberCell } from '../order-table-cells/table-number-cell';
import { OrderStatusCell } from '../order-table-cells/order-status-cell';
import { ButtonCell } from '../order-table-cells/button-cell';
import { CreatedAtCell } from '../order-table-cells/text-cell';
import { ITableColumn, TableCellType } from '../../../common/types';

export const MyOrders:FC = () => {
  const columns:ITableColumn[] = [
    {
      title: 'Table number',
      key: 'table',
      type: TableCellType.TableNumber,
      Component: TableNumberCell,
    },
    {
      title: 'Order time',
      key: 'createdAt',
      type: TableCellType.CreatedAt,
      Component: CreatedAtCell,
    },
    {
      title: 'Order status',
      key: 'status',
      type: TableCellType.OrderStatus,
      Component: OrderStatusCell,
    },
    {
      title: 'View order detail',
      key: 'view',
      type: TableCellType.Button,
      Component: ButtonCell,
    },
  ];

  const fetchMyOrders = () => {
    return ordersService.fetchMyOrders();
  };
  const { data, isLoading } = useQuery('myOrders', fetchMyOrders);

  return (
    <>
      <InnerTitle>My Orders</InnerTitle>
      {
        isLoading ? 'Loading...' : (
          <Table
            columns={columns}
            data={data?.order || null}
          />
        )
      }
    </>
  );
};
