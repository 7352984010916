import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReorderIcon from '../../../../assets/reorder.png';
import { Styled, useStyles } from './category.styled';
import { Button } from '../button';
import { colorPalette } from '../../../../styles/color-palette';
import Dish from '../dish';
import { ICategoryPayload, IDish } from '../../types';
import { useMutation, useQueryClient } from 'react-query';
import { menuService } from '../../services/menu.service';
import { sortOrderByDate } from '../../utils/sortArray';

interface ICategoryProps {
  id: string;
  media: string;
  type: string;
  menuItems: IDish[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onMenuItemEdit: (id: string, category: ICategoryPayload) => void;
  venueId: string;
}

const Category: React.FC<ICategoryProps> = ({
  id,
  media,
  type,
  menuItems = [],
  onEdit,
  onDelete,
  onMenuItemEdit,
  venueId,
}) => {
  const queryClient = useQueryClient();
  const [isOpened, setIsOpened] = useState(false);
  const classes = useStyles({ isOpened });
  const category = { id, media, type, menuItems };
  const history = useHistory();
  const ExpandIcon = () => (
    <IconButton onClick={() => setIsOpened((isOpen) => !isOpen)}>
      <ExpandMoreIcon />
    </IconButton>
  );

  const onDeleteHandler = () => {
    onDelete(id);
  };

  const onEditHandler = () => {
    onEdit(id);
  };

  const addItem = () => {
    const params = qs.stringify({ categoryId: category.id });
    history.push(`/menu/menu-item/?${params}`);
  };

  const deleteMenuItemMutation = useMutation(( { itemId }:any) =>
    menuService.deleteMenuItem(itemId ), {
    onSuccess: () => {
      queryClient.invalidateQueries('menu');
    },
  });
  const deleteItem = (itemId: string) => {
    deleteMenuItemMutation.mutate({ itemId });
  };

  const sortByCreationDate = () => sortOrderByDate(menuItems as IDish[], 'createdAt', 'ASC');

  const sortedMenuItems = sortByCreationDate() || [];

  return (
    <Accordion
      classes={{ root: classes.accordion }}
      expanded={isOpened}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        classes={{ root: classes.summary }}
      >
        <Styled.Content>
          <Styled.Wrapper>
            <Styled.ReorderIcon src={ReorderIcon} />
            <Styled.IconWrapper>
              <Styled.Icon src={media} />
            </Styled.IconWrapper>
            <Styled.Category>{type}</Styled.Category>
            <Styled.ItemsCount>({menuItems.length} item)</Styled.ItemsCount>
          </Styled.Wrapper>
          <Styled.ButtonsWrapper>
            <Button
              text="+Add item"
              background="transparent"
              height="34px"
              width="90px"
              fontWeight={400}
              color={colorPalette.green}
              onClick={addItem}
            />
            <Button
              text="Delete"
              background="transparent"
              height="34px"
              width="70px"
              fontWeight={400}
              color={colorPalette.red}
              onClick={onDeleteHandler}
            />
            <Button
              text="Edit"
              background="transparent"
              height="34px"
              width="55px"
              fontWeight={400}
              onClick={onEditHandler}
            />
          </Styled.ButtonsWrapper>
        </Styled.Content>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        {sortedMenuItems.map(({ id, name, media, description, available, price }) => (
          <Dish
            key={id}
            id={id}
            name={name}
            media={media}
            price={price}
            description={description}
            available={available}
            onDelete={deleteItem}
            category={category}
            venueId={venueId}
            onMenuItemEdit={onMenuItemEdit}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default Category;
