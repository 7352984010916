import React from 'react';
import { Styled } from './sidebar.styled';
import { NavLinkProps } from 'react-router-dom';
import { auth } from '../../services/auth.service';

interface IOwnerlinkProps extends NavLinkProps{
  src: string,
  activeSrc: string,
}

export const OwnerLink: React.FC<IOwnerlinkProps> = (props) => {
  const { children, activeSrc, src, ...otherProps } = props;
  const isOwner = auth.isOwner();

  return isOwner ? (
    <Styled.Link {...otherProps} src={src} activeSrc={activeSrc}>
      {children}
    </Styled.Link>
  ) : null;
};
