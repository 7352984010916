import { ILoginResponse } from '../../../types/login';
import { HttpService } from '../../common/services/http.service';
import { HttpServiceFactory } from '../../common/services/index';
import { ILoginData } from '../types';

export class WaiterService {
  constructor(private httpService: HttpService) {}
  public loginUser(email: string, password: string) {
    return this.httpService.post<ILoginResponse, ILoginData>('waiter-auth/login', { email, password });
  }
};

const factory = new HttpServiceFactory();
export const authWaiterService = new WaiterService(factory.createHttpService());
