import { FC } from 'react';
import { IMap } from '../../../types';

export interface IStatusIndicator {
  color: IIndicatorColors;
}
export type IIndicatorColors = 'red' | 'yellow' | 'green';

export enum TableCellType {
  Button,
  OrderStatus,
  TableNumber,
  CreatedAt,
  TableStatus,
  TableName,
};

export interface ICell {
  item: IMap,
  onClick?: Function,
}

export interface ITableColumn {
  title: string,
  key?: string,
  type: TableCellType,
  onClick?: Function,
  Component: FC<ICell>,
}

export interface IOption {
  name: string,
  current: boolean,
  onClick: Function,
  indicator?: IIndicatorColors,
}
