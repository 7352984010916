import React, { FC } from 'react';
import { IIndicatorColors } from '../../types';
import { Styled } from './indicator.styled';

interface IIndicator {
  color: IIndicatorColors
}

export const Indicator:FC<IIndicator> = ({ color }) => {
  return (
    <Styled.StatusIndicator color={color}/>
  );
};
