import styled from 'styled-components';
import ArrowBack from '../../../../assets/arrow-back.png';

const Back = styled.div`
  background: url(${ArrowBack});
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 20px;
  margin: ${({ theme }) => theme.spaces.sm} 0 ${({ theme }) => theme.spaces.md};
  &:hover{
    cursor: pointer;
  }
`;
export const Styled = {
  Back,
};
