import styled from 'styled-components';

const ModalTitle = styled.h2`
  ${({ theme }) => theme.fonts.xl}
  text-align: center;
`;
const ModalSubtitle = styled.p`
  font-size: ${({ theme }) => theme.fonts.md};
  margin-bottom: ${({ theme }) => theme.spaces.xl};
  text-align: center;
`;

export const Styled = {
  ModalTitle,
  ModalSubtitle,
};
