import styled from 'styled-components';
import { Alignment } from '../../constants';

interface ITableCell{
  alignment?: Alignment,
}

const TableWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.xxl};
  @media (max-width: 767px){
    min-width: 767px;
    overflow-y: scroll;
  }
`;

const TableHead = styled.div`
  display: flex;  
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spaces.sm};
  font-weight: 500;
`;

export const TableCell = styled.div<ITableCell>`
  width: 100%;
  text-align: ${({ alignment }) => alignment};
  font-size: ${({ theme }) => theme.fonts.md};
  &:first-child{
    padding-left: ${({ alignment, theme }) => alignment !== Alignment.CENTER ? theme.spaces.lg : '0px'};
  }
`;

const TableBody = styled.div`
  display: flex;  
  justify-content: space-between;
  flex-direction: column;
`;

export const Styled = {
  TableBody,
  TableWrapper,
  TableHead,
  TableCell,
};
