import styled from 'styled-components';
import { IIndicatorColors } from '../../../../common/types';

interface IOrderStatus{
  color: IIndicatorColors,
}

const Card = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spaces.lg} ${theme.spaces.xxl} ${theme.spaces.lg} ${theme.spaces.lg};`};

  @media (max-width: 900px){
    padding: ${({ theme }) => `${theme.spaces.md} ${theme.spaces.md} ${theme.spaces.md} ${theme.spaces.md};`}  
  }
`;

const TopCard = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  padding-bottom: ${({ theme }) => theme.spaces.xs};
`;

const OrderStatus = styled.span<IOrderStatus>`
  color: ${({ theme, color }) => theme.colors[color]};
`;
const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  &>span{
    margin-bottom: ${({ theme }) => theme.spaces.xxs}
  }
`;

const RightBlock = styled.div`
`;
const BottomCard = styled.div`
  padding-top: ${({ theme }) => theme.spaces.xl};
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spaces.xs};
`;


const ButtonContainer = styled.div`
  &>button{
    width: 220px;
    font-size: ${({ theme }) => theme.fonts.lg};
    font-weight: 500;
  }
`;

export const Styled = {
  Card,
  OrderStatus,
  TopCard,
  LeftBlock,
  RightBlock,
  BottomCard,
  ButtonRow,
  ButtonContainer,
};
