import React, { FC } from 'react';
import { Indicator } from '../../../../common/components/indicator';
import { ICell } from '../../../../common/types';
import { colors, orderStatuses } from '../../../../orders/constants';
import { IStatus } from '../../../../orders/types';
import { Styled } from './table-status.styled';

export const TableStatus:FC<ICell> = ({ item }) => {
  const status: IStatus = item.openOrders[0]?.status;

  return (
    <Styled.TableStatus>
      <Styled.SelectWrapper>
        <Indicator
          color={colors[status] || 'green'}
        />
        <Styled.Status>
          {item.openOrders[0] ? orderStatuses[status] : 'Free'}
        </Styled.Status>
      </Styled.SelectWrapper>
    </Styled.TableStatus>
  );
};
