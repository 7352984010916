import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spaces.sm};
  right: ${({ theme }) => theme.spaces.sm}; 
  border-radius: 50px;
  border: none;
  width: 40px;
  height: 40px;
  font-size: ${({ theme }) => theme.fonts.lg};
  line-height: 1;
  cursor: pointer;
`;

const Form = styled.form`
  width: 400px;

`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fonts.xl};
  margin-bottom: ${({ theme }) => theme.spaces.md};
  text-align: center;
`;

export const Styled = {
  Wrapper,
  CloseButton,
  Form,
  Title,
};
