import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../../../common/components/button';
import { auth } from '../../../../common/services/auth.service';
import { dateService } from '../../../../common/services/date.service';
import { colors } from '../../../constants';
import { IOrder } from '../../../types';
import { useChangeStatus } from '../../../utils/change-status';
import { OrderDishes } from '../order-dishes';
import { Summary } from '../order-summary';
import { Styled } from './order-card.styled';

interface IOrderCardProps {
  order: IOrder | null,
}

export const OrderCard:FC<IOrderCardProps> = ({ order }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const changeStatus = useChangeStatus();
  const history = useHistory();

  const onCloseHandler = () => {
    changeStatus.mutate({ id: order?.id, status: 'completed' });
    history.push('/orders');
  };
  const onDeleteHandler = () => {
    changeStatus.mutate({ id: order?.id, status: 'deleted' });
    history.push('/orders');
  };
  const isOwner = auth.isOwner();

  if (order) {
    return (
      <>
        <Styled.Card>
          <Styled.TopCard>
            <Styled.LeftBlock>
              <span>Order ID: {order.numberId} (
                <Styled.OrderStatus color={colors[order.status.name]}>{order.status.name}</Styled.OrderStatus>)</span>
              <span>{order.table.name}</span>
              {order.waiter ? (
            <span>Waiter: {order.waiter.name}</span>
            ) : null}
            </Styled.LeftBlock>
            <Styled.RightBlock>
              {dateService.stringToLocaleDate(order.createdAt)}
            </Styled.RightBlock>
          </Styled.TopCard>
          <Styled.BottomCard>
            <OrderDishes
              orderStatus={order.status.name}
              items={order.orderItems}
              setIsButtonDisabled={setIsButtonDisabled}
            />
            <Summary
              summary={order.amount.amount}
            />
          </Styled.BottomCard>
        </Styled.Card>
        {order.status.name !== 'completed' && (
          <Styled.ButtonRow>
            <Styled.ButtonContainer>
              {isOwner && (
                <Button
                  background="primary"
                  rounded={false}
                  onClick={onDeleteHandler}
                >DELETE ORDER</Button>
              )}
            </Styled.ButtonContainer>
            <Styled.ButtonContainer>
              <Button
                background="secondary"
                rounded={false}
                onClick={onCloseHandler}
                disabled={isButtonDisabled}
              >CLOSE ORDER</Button>
            </Styled.ButtonContainer>
          </Styled.ButtonRow>
        )}
      </>
    );
  } else {
    return <p>Loading...</p>;
  }
};
