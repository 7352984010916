import styled from 'styled-components';

const Title = styled.h1`
    font-weight: 500;
    text-transform: uppercase;
    font-size: 24px;
`;

export const Styled = {
  Title,
};
