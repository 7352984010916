import styled from 'styled-components';

const OrderItemWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spaces.md};
  margin-bottom: ${({ theme }) => theme.spaces.md};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
`;

const OrderDish = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spaces.md};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fonts.lg};
  min-width: 200px;
  max-width: 600px;
  word-break: break-all;
`;

const Amount = styled.div`
  font-size: ${({ theme }) => theme.fonts.lg};
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Status = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  line-height: 1;
  &:hover{
    cursor: pointer;
  }
`;

const Price = styled.div`
  font-size: ${({ theme }) => theme.fonts.lg};
  min-width: 70px;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Comments = styled.ol`
  list-style-position: inside;
`;

export const Styled = {
  OrderDish,
  Title,
  Amount,
  Status,
  Price,
  OrderItemWrapper,
  Comments,
};
