import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Styled } from './sidebar.styled';
import ProfileIcon from '../../../../assets/home.svg';
import ProfileIconActive from '../../../../assets/home-red.svg';
import WaiterIcon from '../../../../assets/waiter.svg';
import WaiterIconActive from '../../../../assets/waiter-red.svg';
import OrdersIcon from '../../../../assets/orders.svg';
import OrdersIconActive from '../../../../assets/orders-red.svg';
import MenuIcon from '../../../../assets/menu.svg';
import MenuIconActive from '../../../../assets/menu-red.svg';
import TablesIcon from '../../../../assets/tables.svg';
import TablesIconActive from '../../../../assets/tables-red.svg';
import ExitIcon from '../../../../assets/logout.svg';
import Logo from '../../../../assets/logo-white.png';
import { auth } from '../../services/auth.service';
import { LogoutModal } from '../../../logout';
import { roleNames, storeKeys } from '../../constants';
import { OwnerLink } from './owner-link.component';
import { userService } from '../../services/user.service';


export const Sidebar = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { data } = useQuery('profile', () => userService.getProfile(), {
    onSuccess: (data) => {
      data && localStorage.setItem(storeKeys.profile, JSON.stringify(data));
    },
    onError: () => {
      localStorage.removeItem(storeKeys.profile);
    },
  });

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const onSubmit = () => {
    auth.logout();
    closeModal();
  };


  return (
    <>
      <Styled.SideBarSection>
        <Styled.Logo src={Logo}/>
        <Styled.CurrentUserRole>
          {roleNames[data?.role?.[0]!] || 'Loading...'}
        </Styled.CurrentUserRole>
        <Styled.MenuWrapper>
          <Styled.NavMenu>
            <Styled.Link to="/" exact src={ProfileIcon} activeSrc={ProfileIconActive}>
              <div />
              Profile
            </Styled.Link>
            <OwnerLink to="/add-waiter" exact src={WaiterIcon} activeSrc={WaiterIconActive}>
              <div/>
              +Add
            </OwnerLink>
            <Styled.Link to="/orders" exact src={OrdersIcon} activeSrc={OrdersIconActive}>
              <div/>
              Orders
            </Styled.Link>
            <OwnerLink to="/menu" exact src={MenuIcon} activeSrc={MenuIconActive}>
              <div/>
              Menu
            </OwnerLink>
            <Styled.Link to="/tables" exact src={TablesIcon} activeSrc={TablesIconActive}>
              <div/>
              Tables
            </Styled.Link>
          </Styled.NavMenu>
          <Styled.LogoutLink onClick={openModal}>
            <Styled.MenuIcon src={ExitIcon}/>
              Exit
          </Styled.LogoutLink>
        </Styled.MenuWrapper>
      </Styled.SideBarSection>
      <LogoutModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onSubmit={onSubmit}
      />
    </>
  );
};

