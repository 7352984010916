import React, { FC } from 'react';
import { WaiterForm } from '../waiter-form';
import { PageTitle } from '../../../common/components/page-title';
import { ArrowBack } from '../../../common/components/arrow-back';
import { IUser } from '../../../../types';
import { IWaiterModeComponent } from '../../types';

interface IWaiterEditProps extends IWaiterModeComponent {
  currentWaiter: IUser | null,
}

export const WaiterEdit:FC<IWaiterEditProps> = ({ setMode, currentWaiter }) => {
  const onArrowClickHandler = () => {
    setMode('list');
  };

  return (
    <>
      <PageTitle>EDIT DATA</PageTitle>
      <ArrowBack onClick={onArrowClickHandler}/>
      <WaiterForm
        setMode={setMode}
        currentWaiter={currentWaiter}
      />
    </>
  );
};
