import React from 'react';
import { useQuery } from 'react-query';
import { storeKeys, userRoles } from '../../../modules/common/constants';
import { userService } from '../../../modules/common/services/user.service';
import { ErrorPage } from '../../../modules/error';
import { PrivateRoute, PrivateRouteProps } from '../../private-route';

export const OwnerRoute = (props: PrivateRouteProps) => {
  const { data, isLoading } = useQuery('profile', () => userService.getProfile(), {
    onSuccess: (data) => {
      data && localStorage.setItem(storeKeys.profile, JSON.stringify(data));
    },
    onError: () => {
      localStorage.removeItem(storeKeys.profile);
    },
  });
  const isOwner = data?.role[0] === userRoles.owner;

  return isOwner ? (
    <PrivateRoute {...props} />
  ) : isLoading ? null :
   <ErrorPage/>;
};
