import React, { FC } from 'react';
import { Styled } from './arrow-back.styled';

interface IArrowBackProps {
  onClick: () => void;
};

export const ArrowBack:FC<IArrowBackProps> = ({ onClick }) => {
  return (
    <Styled.Back onClick={onClick}/>
  );
};
