import { HttpServiceFactory } from '../../common/services';
import { EnhancedWithAuthHttpService } from '../../common/services/http-auth.service';
import { IOrder, IOrderItemStatus, IOrderItemStatusData, IOrdersResponse, IStatus, IStatusData } from '../types';


export class OrdersService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}
  public fetchOrders() {
    return this.httpService.get<IOrdersResponse>('order');
  }
  public fetchOrder(id: string) {
    return this.httpService.get<IOrder>(`order/${id}`);
  }
  public fetchCompletedOrders() {
    return this.httpService.get<IOrdersResponse>('order?status=completed');
  }
  public fetchMyOrders() {
    return this.httpService.get<IOrdersResponse>('order?myOrders=true&status=accepted');
  }
  public changeOrderStatus(id: string, status: IStatus) {
    return this.httpService.put<IOrdersResponse, IStatusData>(`order/change-status/${id}`, { status });
  }
  public changeOrderItemStatus(id: string, status: IOrderItemStatus) {
    return this.httpService.put<IOrdersResponse, IOrderItemStatusData>(`order-item/${id}`, { status });
  }
};

const factory = new HttpServiceFactory();
export const ordersService = new OrdersService(factory.createAuthHttpService());
