import styled from 'styled-components';

const HomeWrapper = styled.div`
  padding: ${({ theme }) => `0 ${theme.spaces.lg} ${theme.spaces.md} `};
  @media (max-width: 767px){
    min-width: 767px;
    overflow-y: scroll;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AccordionWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.sm};
`;

export const Styled = {
  ButtonWrapper,
  HomeWrapper,
  AccordionWrapper,
};
