import React from 'react';
import Category from '../category';
import { Styled } from './menu.styled';
import { ICategory, ICategoryPayload } from '../../types';
import { sortOrderByDate } from '../../utils/sortArray';

interface IMenuProps {
  data?: ICategory[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onMenuItemEdit: (id: string, category: ICategoryPayload) => void;
  venueId: string;
}

export const Menu: React.FC<IMenuProps> = ({
  onEdit,
  onDelete,
  data = [],
  onMenuItemEdit,
  venueId,
}) => {
  const onSortByCreationDate = () => sortOrderByDate(data as ICategory[], 'createdAt', 'ASC');

  const sortedData = onSortByCreationDate() || [];

  return (
    <Styled.HomeWrapper>
      <Styled.AccordionWrapper>
        {sortedData?.map(({ id, type, media, menuItems }) => (
          <Category
            key={id}
            id={id}
            type={type}
            media={media}
            menuItems={menuItems}
            onDelete={onDelete}
            onEdit={onEdit}
            onMenuItemEdit={onMenuItemEdit}
            venueId={venueId}
          />
        ))}
      </Styled.AccordionWrapper>
    </Styled.HomeWrapper>
  );
};
