import { IOrder, IOrdersResponse } from '../types';

export const sortOrdersDataByStatus = (data: IOrdersResponse) => {
  return data?.order.sort((a:IOrder, b:IOrder) => {
    if (a.status.name === 'placed') {
      return -1;
    }
    if (b.status.name === 'placed') {
      return 1;
    }
    return 0;
  });
};

type order = 'ASC' | 'DESC'

export const sortOrdersDataByKeyValue = <T extends object, R extends keyof T>
  (arr: T[], param: R, order: order = 'ASC'): T[] => {
  return arr?.sort((item1, item2) => {
    if (item1[param] > item2[param]) {
      return order === 'ASC' ? 1 : -1;
    } else if (item1[param] < item2[param]) {
      return order === 'DESC' ? 1 : -1;
    }
    return 0;
  });
};
