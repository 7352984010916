import React, { FC } from 'react';
import Modal from 'react-modal';
import ExclPointIcon from '@material-ui/icons/PriorityHigh';
import { Button } from '../button';
import { Styled } from './notification-modal.styled';

interface INotificationModalProps {
  isOpen: boolean;
  onRequestClose: (event: React.MouseEvent) => void;
  title?: string;
  subtitle?: string;
  icon?: JSX.Element;
  paymentMethod?: string,
};

const defaultIcon = <ExclPointIcon fontSize="inherit" />;

export const NotificationModal: FC<INotificationModalProps> = ({
  isOpen,
  onRequestClose,
  icon = defaultIcon,
  title,
  subtitle,
  paymentMethod,
}) => {
  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Logout form"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 2,
        },
        content: {
          boxSizing: 'border-box',
          inset: '0px',
          position: 'relative',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'hidden',
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
          padding: '40px 60px',
          width: '530px',
        },
      }}
    >
      <Styled.Content>
        <Styled.IconWrapper>
          {icon}
        </Styled.IconWrapper>
        <Styled.TextWrapper>
          {title && <Styled.Title>{title}</Styled.Title>}
          {subtitle && <Styled.Title>{subtitle}</Styled.Title>}
          {paymentMethod && <Styled.Title>Payment method: {paymentMethod}</Styled.Title>}
        </Styled.TextWrapper>
        <Button
          background="primary"
          onClick={onRequestClose}
          width="185px"
          height="50px"
        >
          <Styled.ButtonText>
            OK
          </Styled.ButtonText>
        </Button>
      </Styled.Content>
    </Modal>
  );
};
