import styled from 'styled-components';

const Card = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.primary};
  min-width: 160px;
  flex-basis: 22%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spaces.md};
  margin-right: ${({ theme }) => theme.spaces.sm};
  padding: 0 ${({ theme }) => theme.spaces.xs};
  min-height: 188px;
  &:hover{
    cursor: pointer;
  }
  @media(max-width: 800px){
    flex-basis: 44%;
  }
`;

const Plus = styled.span`
  font-size: 80px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.white};
`;

const Subtitle = styled.span`
  font-size: ${({ theme }) => theme.fonts.xl};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const Styled = {
  Card,
  Plus,
  Subtitle,
};
