import * as Yup from 'yup';
import { getEmailValidator } from '../../common/utils/get-email-validator';
import { getPasswordValidator } from '../../common/utils/get-password-validator';
import { getWaiterPasswordValidator } from '../../common/utils/get-waiter-password-validator';

export const ownerLoginValidationSchema = Yup.object().shape({
  email: getEmailValidator(),
  password: getPasswordValidator().required('No password provided.'),
});

export const waiterLoginValidationSchema = Yup.object().shape({
  email: getEmailValidator(),
  password: getWaiterPasswordValidator().required('No password provided.'),
});
