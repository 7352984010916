import React, { FC, useState } from 'react';
import { IDishOption, IDishPayload } from '../../../types';
import { Styled } from './edit-modal-item.styled';
import { Button } from '../../../../common/components/button';
import { FormikProvider, useFormik } from 'formik';
import { optionValidationSchema } from '../../../utils/option-validation';
import { FormInput } from '../../../../common/components/form-input';


interface IEditModalItemProps{
  option: IDishOption | IDishPayload,
  onEdit: Function,
  onDelete: Function,
}

export const EditModalItem:FC<IEditModalItemProps> = ({ option, onEdit, onDelete }) => {
  const formik = useFormik({
    initialValues: {
      optionName: option.name,
      optionPrice: option.price,
    },
    validationSchema: optionValidationSchema,
    onSubmit: ({ optionName, optionPrice }) => {
      saveOption(optionName, optionPrice);
    },
  });
  const [isEditModeEnable, setIsEditModeEnable] = useState<boolean>(false);

  const enableEditMode = () => {
    setIsEditModeEnable(true);
  };

  const saveOption = (name: string, price: number) => {
    const optionPayload = { name, price };
    onEdit({ id: option.id, option: optionPayload });
    setIsEditModeEnable(false);
  };

  const onDeleteHandler = () => {
    onDelete({ id: option.id });
  };

  return (
    <>
      {isEditModeEnable ? (
        <>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Styled.ItemWrapper>
                <Styled.Name>
                  <FormInput
                    type="text"
                    name="optionName"
                    isEdit={true}
                    maxLength={100}
                  />
                </Styled.Name>
                <Styled.Price>
                  <FormInput
                    type="number"
                    name="optionPrice"
                    min={0.01}
                    step={0.01}
                    isEdit={true}
                  />
                </Styled.Price>
                <Styled.Edit>
                  <Button type="submit" width="70px" height="34px">
                    Save
                  </Button>
                </Styled.Edit>
                <Styled.Delete onClick={onDeleteHandler}></Styled.Delete>
              </Styled.ItemWrapper>
            </form>
          </FormikProvider>
        </>
      ) : (
        <Styled.ItemWrapper>
          <Styled.Name>{option.name}</Styled.Name>
          <Styled.Price>{option.price}€</Styled.Price>
          <Styled.Edit>
            <Button
              onClick={enableEditMode}
              width="70px"
              height="34px"
              type="submit">
              Edit
            </Button>
          </Styled.Edit>
          <Styled.Delete onClick={onDeleteHandler}></Styled.Delete>
        </Styled.ItemWrapper>
      )}
    </>
  );
};
