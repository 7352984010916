import { MockDB } from '../modules/common/services/mock-http.service';
import { HttpMethods } from '../types';
import Pasta from '../assets/pasta.png';

export default function(baseURL: string) {
  MockDB.connection().addRecord(baseURL + '/api/menu', {
    [HttpMethods.GET]: {
      status: 200,
      data: [
        {
          id: '1',
          type: 'Pasta',
          createdAt: '2021-10-14T14:02:55.228Z',
          updatedAt: '2021-10-14T14:02:55.228Z',
          media: Pasta,
          menuItems: [
            {
              id: '1',
              createdAt: '2021-10-14T14:02:55.228Z',
              updatedAt: '2021-10-14T14:02:55.228Z',
              price: 10.5,
              name: 'Tuna Pasta',
              available: true,
              description: 'Pasta, tuna, onion, garlic, celery, nutmeg, lemon juice, salt, ground black pepper',
            },
            {
              id: '2',
              createdAt: '2021-10-14T14:02:55.228Z',
              updatedAt: '2021-10-14T14:02:55.228Z',
              price: 10.5,
              name: 'Tuna Pasta2',
              available: false,
              media: Pasta,
              description: 'Pasta, tuna, onion, garlic, celery, nutmeg, lemon juice, salt, ground black pepper',
            },
          ],
        },
      ],
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
}
