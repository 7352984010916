import styled from 'styled-components';
import arrowLeft from '../../../../assets/arrow-left.png';

const Wrapper = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => `0px ${theme.spaces.lg} ${theme.spaces.lg}`};
`;

const TopButtonWrapper = styled.div`
  justify-content: space-between;
  display: flex;
`;

const BackButton = styled.div`
  background-image: url(${arrowLeft});
  height: 40px;
  width: 40px;
  background-size: cover;
  margin: ${({ theme }) => `${theme.spaces.xs} 0 ${theme.spaces.md}`};
  cursor: pointer;
`;

const Form = styled.form`
  display: block;
  height: auto;
  color: ${({ theme }) => theme.colors.black};
`;

const FormFields = styled.div`
  display: flex;
  @media(max-width: 800px){
    flex-direction: column;
    align-items: center;
  }
`;

const OptionsWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.sm};
`;

const OptionsList = styled.ol`
  margin-bottom: ${({ theme }) => theme.spaces.xs};
  padding-left: 0;
  list-style-position: inside;
`;

const OptionsTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spaces.xs};
`;

const OptionsButtonWrapper = styled.div`
  width: 50%;
`;

const BaseTitle = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-size: ${({ theme }) => theme.fonts.xl};
  line-height: 28px;
  letter-spacing: -0.3px;
  margin: 0;
  font-weight: 500;

`;

const Title = styled(BaseTitle)`
  font-weight: 500;
`;

const Subtitle = styled(BaseTitle)`
  font-size: ${({ theme }) => theme.fonts.lg};
  margin-bottom: ${({ theme }) => theme.spaces.md};
  @media(max-width: 800px){
    text-align: center;
  }
`;

const HeaderWrapper = styled.div``;

const InputContainer = styled.div`
  margin-right: ${({ theme }) => theme.spaces.xxl};
  width: 400px;
  @media(max-width: 800px){
    margin-right: 0px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media(max-width: 800px){
    order: -1;
    width: 400px;
    margin-bottom: ${({ theme }) => theme.spaces.sm};
  }
`;

const ButtonsContainer = styled.div`
  max-width: 400px;
`
;

const ClearButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spaces.xs};
`;

const Preview = styled.img`
  width: 318px;
  height: 160px;
  @media(max-width: 800px){
    width: 100%;
    height: auto;
  }
`;

export const Styled = {
  Wrapper,
  ContentWrapper,
  BackButton,
  TopButtonWrapper,
  InputContainer,
  ImageContainer,
  HeaderWrapper,
  ButtonsContainer,
  Form,
  OptionsTitle,
  OptionsList,
  OptionsWrapper,
  OptionsButtonWrapper,
  Title,
  Subtitle,
  Preview,
  FormFields,
  ClearButtonContainer,
};
