import styled from 'styled-components';

const Form = styled.form`
width: 330px;
`;
const ButtonContainer = styled.div`
width: 220px;
margin-top: ${({ theme }) => theme.spaces.lg};
&>button{
  margin-bottom: ${({ theme }) => theme.spaces.xs};
}
`;


export const Styled = {
  Form,
  ButtonContainer,
};
