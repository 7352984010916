import { HttpServiceFactory } from '../../common/services';
import { EnhancedWithAuthHttpService } from '../../common/services/http-auth.service';
import { ITable, ITablePayload } from '../types';

export class TablesService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}
  public fetchTables() {
    return this.httpService.get<ITable[]>('table');
  }
  public updateTableName(id: string, tableName: string) {
    return this.httpService.put<ITable, ITablePayload>(`table/${id}`, { tableName });
  }
};

const factory = new HttpServiceFactory();
export const tablesService = new TablesService(factory.createAuthHttpService());
