import { DefaultEventsMap } from '@socket.io/component-emitter';
import io, { Socket } from 'socket.io-client';

const serverUrl = process.env.REACT_APP_SOCKET_URL || '';

export class SocketService {
  public socket: Socket<DefaultEventsMap, DefaultEventsMap> | null = null

  public createSocket(token: string ) {
    if (!this.socket) {
      this.socket = io(serverUrl, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  }
}

export const socketService = new SocketService();
