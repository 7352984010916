import { IDishOption, IDishOptionWithoutId } from './../types/index';
import { HttpServiceFactory } from '../../common/services';
import { ROUTES } from '../../common/constants';
import { EnhancedWithAuthHttpService } from '../../common/services/http-auth.service';

export class MenuItemOptionService {
  private httpService: EnhancedWithAuthHttpService;

  constructor(private httpFactory: HttpServiceFactory) {
    this.httpService = this.httpFactory.createAuthHttpService();
  }

  public addMenuItemOption(id: string, option: IDishOptionWithoutId): Promise<IDishOption | void> {
    return this.httpService.post<IDishOption, IDishOptionWithoutId>(`${ROUTES.ROUTES_MENU_ITEM_OPTION}/${id}`, option);
  }

  public editMenuItemOption(id: string, option: IDishOptionWithoutId): Promise<IDishOption | void> {
    return this.httpService.put<IDishOption, IDishOptionWithoutId>(`${ROUTES.ROUTES_MENU_ITEM_OPTION}/${id}`, option);
  }

  public deleteMenuItemOption(id: string): Promise<void> {
    return this.httpService.delete(`${ROUTES.ROUTES_MENU_ITEM_OPTION}/${id}`);
  }
}

export const menuItemOptionService = new MenuItemOptionService(new HttpServiceFactory());
