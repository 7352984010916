import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AppModal } from '../../../app-modal';
import { Button } from '../../../common/components/button';
import { FormInput } from '../../../common/components/form-input';
import { ITable } from '../../../orders/types';
import { tablesService } from '../../services/tables.service';
import { ITablePayload } from '../../types';
import { TableValidationSchema } from '../../utils/tables-validation-schema';
import { Styled } from './table-modal.styled';

interface ITableModal {
  table: ITable,
  isOpen: boolean,
  onRequestClose(): void
}
export const TableModal:FC<ITableModal> = ({ table, isOpen, onRequestClose }) => {
  const queryClient = useQueryClient();

  const {
    mutate: changeTableMutation,
  } = useMutation(({ tableName }: ITablePayload) =>
    tablesService.updateTableName(table.id, tableName), {
    onSuccess: () => {
      queryClient.invalidateQueries('tables');
    },
  },
  );

  const formik = useFormik({
    initialValues: {
      tableName: table?.name || '',
    },
    onSubmit: ({ tableName }) => {
      changeTableMutation({ tableName });
      onRequestClose();
    },
    validationSchema: TableValidationSchema,
    enableReinitialize: true,
  });

  return (
    <AppModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}>
      <Styled.CloseButton
        onClick={onRequestClose}
      >x</Styled.CloseButton>
      <Styled.Title>
        Change Table Name
      </Styled.Title>
      <FormikProvider value={formik}>
        <Styled.Form onSubmit={formik.handleSubmit}>
          <FormInput
            name="tableName"
            type="text"
            placeholder="Enter table name"
          />
          <Button
            type="submit"
            rounded={true}
            background="primary"
          >Submit</Button>
        </Styled.Form>
      </FormikProvider>
    </AppModal>
  );
};
