import React, { useState } from 'react';
import { MainRouter } from './navigation/router';
import { QueryClient, QueryClientProvider,
} from 'react-query';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles';
import { initMocks } from './__mocks__';
import { storeKeys } from './modules/common/constants';
import { socketService } from './modules/common/services/socket-service';
import { auth } from './modules/common/services/auth.service';

if (process.env.REACT_APP_MOCK === 'enabled') {
  initMocks();
}

const queryClient = new QueryClient();

const App = () => {
  const [isAuth, setIsAuth] = useState(auth.isAuthorized());

  const token = localStorage.getItem(storeKeys.token);
  if (token && isAuth) {
    socketService.createSocket(token);
  }

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <MainRouter
          isAuth={isAuth}
          setIsAuth={setIsAuth}
        />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
