import React, { FC } from 'react';
import { Styled } from './page-title.styled';

export const PageTitle:FC = ({ children }) => {
  return (
    <Styled.Title>
      { children }
    </Styled.Title>
  );
};
