import { modalTypes } from '../../modules/common/constants';

export const getModalStateObject = (
    modalType = modalTypes.none,
    tableName = '',
    paymentMethod = '') => {
  return {
    modalType,
    tableName,
    paymentMethod,
  };
  ;
};
