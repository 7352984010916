import React, { FC } from 'react';
import Payment from '../../../../assets/payment.png';
import { NotificationModal } from '../../../common/components/notification-modal';

interface IRequestedBillModalProps {
  isOpen: boolean;
  paymentMethod: string;
  onRequestClose: (event: React.MouseEvent) => void;
  tableName: string;
};

export const RequestedBillModal: FC<IRequestedBillModalProps> = ({
  isOpen,
  onRequestClose,
  tableName,
  paymentMethod,
}) => {
  const paymentIcon = <img src={Payment} />;
  return (
    <NotificationModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="The customer has requested a bill!"
      subtitle={tableName}
      paymentMethod={paymentMethod}
      icon={paymentIcon}
    />
  );
};
