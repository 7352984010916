import { makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { colorPalette } from '../../../../styles/color-palette';
import { spaces } from '../../../../styles/spaces';

interface StyleProps {
  isOpened?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    padding: `${spaces.xs}`,
  },
  accordion: {
    border: ({ isOpened }) => isOpened ? `1px solid ${colorPalette.black}` : 'none',
    marginBottom: spaces.xxs,
  },
  summary: {
    background: colorPalette.white,
    cursor: 'default !important',
  },
  details: {
    padding: `${spaces.sm} ${spaces.xs}`,
    flexDirection: 'column',
  },
}));

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Category = styled.h5`
  width: 45%;
  margin: 0 ${({ theme }) => theme.spaces.xs} 0 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.lg};
  line-height: 23px;
  letter-spacing: -0.3px;
  word-break: break-word;

`;

const ItemsCount = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.md};
  line-height: 19px;
  letter-spacing: -0.3px;
`;

const ReorderIcon = styled.img`
  margin-right: ${({ theme }) => theme.spaces.sm};
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: ${({ theme }) => theme.spaces.xs};
`;

export const Styled = {
  Content,
  Wrapper,
  ButtonsWrapper,
  Category,
  ItemsCount,
  ReorderIcon,
  IconWrapper,
  Icon,
};
