import React, { FC } from 'react';
import { Select } from '../../../../common/components/select';
import { ICell } from '../../../../common/types';
import { orderStatuses } from '../../../constants';
import { IStatus } from '../../../types';
import { useChangeStatus } from '../../../utils/change-status';
import { Styled } from './order-status-cell.styled';


export const OrderStatusCell:FC<ICell> = ({ item }) => {
  const changeStatus = useChangeStatus();
  const id = item.id;
  const currentStatus = item.status.name;
  const options = Object.keys(orderStatuses)
      .filter((status) => status !== 'deleted')
      .map(( status ) => {
        return {
          name: orderStatuses[status as IStatus],
          current: currentStatus === status,
          onClick: () => changeStatus.mutate({ status, id }),
        };
      });

  return (
    <Styled.OrderStatus>
      <Styled.SelectWrapper>
        <Select
          options={options}
        />
      </Styled.SelectWrapper>
    </Styled.OrderStatus>
  );
};
