import React, { FC } from 'react';
import { ICell } from '../../../../common/types';
import { Styled } from './table-name.styled';

export const TableName:FC<ICell> = ({ item }) => {
  return (
    <Styled.TableName>
      {item.name}
    </Styled.TableName>
  );
};
