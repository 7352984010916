import styled from 'styled-components';

const LoginWrapper = styled.div`
  width: 500px;
  height: 700px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fonts.xxl};
  font-weight: 400;
  margin: 0 0 ${({ theme }) => theme.spaces.xs};
`;

const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.fonts.xl};
  margin: 0 0 ${({ theme }) => theme.spaces.lg};
`;

const toggleLink = styled.span`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 332px;
`;

const Logo = styled.img`
  margin-bottom: ${({ theme }) => theme.spaces.md};
  width: 127px;
  height: 45px;
`;

export const Styled = {
  LoginWrapper,
  Title,
  Subtitle,
  ButtonContainer,
  toggleLink,
  Logo,
};
