import styled from 'styled-components';
import { ButtonProps } from './';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fonts.xs};
  padding: ${({ theme }) => theme.spaces.sm};
  border: none;
  margin-bottom: ${({ theme }) => theme.spaces.sm};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ background, theme }) => background === 'primary' ?
  theme.colors.primary : theme.colors.secondary};
  border-radius: ${({ rounded }: ButtonProps) => rounded ? '10px' : '0px'};
  &:disabled {
    background-color: ${({ theme }) => theme.colors.btn_disabled}
  }
  &:not(:disabled):hover {
    cursor: pointer; 
    background-color: ${({ background, theme }) => background === 'primary' ?
    theme.colors.secondary : theme.colors.primary};
  };
`;

export const Styled = {
  Button,
};
