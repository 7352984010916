import React, { FC } from 'react';
import { Styled } from './waiter-card.styled';
import EditButton from '../../../../assets/pencil.png';
import WaiterAvatar from '../../../../assets/waiter-black.png';
import { IUser } from '../../../../types/';
import { IWaiterModeComponent } from '../../types';

interface IWaiterCardProps extends IWaiterModeComponent{
  waiter: IUser,
  setCurrentWaiter: (waiter: IUser) => void,
}

export const WaiterCard:FC<IWaiterCardProps> = ({ waiter, setMode, setCurrentWaiter }) => {
  const onClickHandler = () => {
    setCurrentWaiter(waiter);
    setMode('edit');
  };

  return (
    <Styled.Card>
      <Styled.EditButton
        onClick={onClickHandler}
        src={EditButton}/>
      <Styled.WaiterAvatar src={WaiterAvatar}/>
      <Styled.WaitersName>
        {waiter.name}
      </Styled.WaitersName>
      <Styled.InfoBox>
        <Styled.InfoRow>
          <strong>Email: </strong> {waiter.email}
        </Styled.InfoRow>
      </Styled.InfoBox>
    </Styled.Card>
  );
};
