import styled from 'styled-components';

const OrderStatus = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.md};
  position: relative;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
`;

const SelectWrapper = styled.div`
  width: 160px;
`;

export const Styled = {
  OrderStatus,
  SelectWrapper,
};
