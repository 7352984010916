import React, { FC } from 'react';
import Modal from 'react-modal';

interface AppModalProps{
  isOpen: boolean,
  onRequestClose: (event: React.MouseEvent) => void,
};

export const AppModal:FC<AppModalProps> = ({ children, isOpen, onRequestClose }) => {
  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Logout form"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 2,
        },
        content: {
          boxSizing: 'border-box',
          inset: '0px',
          position: 'relative',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
          padding: '70px 70px 60px',
        },
      }}
    > {children}</Modal>
  );
};
