import { IOrderColors, IOrderMessages } from '../types';

export const colors:IOrderColors = {
  placed: 'yellow',
  completed: 'green',
  accepted: 'red',
  deleted: 'red',
};

export const orderStatuses:IOrderMessages = {
  accepted: 'Order accepted',
  placed: 'Order placed',
  completed: 'Order completed',
  deleted: 'Order deleted',
};

export const ownerOrderStatuses = {
  placed: 'Order placed',
  completed: 'Order completed',
};


