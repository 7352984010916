import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const InputGroup = styled.div`
 width: 330px;
  margin-bottom: ${({ theme }) => theme.spaces.sm}
`;
const ButtonWrapper = styled.div`
  width: 220px;
`;

export const Styled = {
  InputGroup,
  ButtonWrapper,
  Form,
};
