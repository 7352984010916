import styled from 'styled-components';

const WaiterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spaces.xl};
`;
export const Styled = {
  WaiterList,
};
