export const colorPalette = {
  white: '#F7F8F9',
  black: '#000000',
  primary: '#E42121',
  secondary: '#ff4d4d',
  green: '#2c3e50',
  red: '#F22100',
  yellow: '#E2CE19',
  background: '#f0f0f0',
  btn_disabled: '#c5c5c5',
};
