import React, { FC } from 'react';
import { converseToFixed } from '../../../utils/amount-to-fixed';
import { Styled } from './summary.styled';

interface ISummaryProps{
  summary: number
}

export const Summary:FC<ISummaryProps> = ({ summary }) => {
  return (
    <Styled.Summary>
      <span>Total Amount</span>
      <span>{converseToFixed(summary)} $</span>
    </Styled.Summary>
  );
};
