import React, { FC, useEffect } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router';
import { storeKeys } from '../common/constants';
import { socketService } from '../common/services/socket-service';
import { LoginFormOwner } from './components/login-form-owner';
import { LoginFormWaiter } from './components/login-form-waiter';
import { LoginSelect } from './components/login-select';
import { Styled } from './login.styled';
import { ILoginFormProps } from './types';
import Logo from '../../assets/logo.svg';

export const Login:FC<ILoginFormProps> = ({ setIsAuth }) => {
  const { path } = useRouteMatch();
  useEffect(() => {
    return () => {
      const token = localStorage.getItem(storeKeys.token);
      if (token) {
        socketService.createSocket(token);
      }
    };
  });

  return (
    <Styled.LoginWrapper>
      <Styled.Logo src={Logo}/>
      <Styled.Title>Welcome to Chili!</Styled.Title>
      <Switch>
        <Route path={`${path}`} exact component={LoginSelect}/>
        <Route path={`${path}/waiter`} exact>
          <LoginFormWaiter setIsAuth={setIsAuth}/>
        </Route>
        <Route path={`${path}/owner`} exact>
          <LoginFormOwner setIsAuth={setIsAuth}/>
        </Route>
        <Route path={`${path}/:random`}>
          <Redirect to="/error" />
        </Route>
        <Redirect to="/login" />
      </Switch>
    </Styled.LoginWrapper>
  );
};
