import { MockDB } from '../modules/common/services/mock-http.service';
import { HttpMethods } from '../types';

export default function(baseURL: string) {
  MockDB.connection().addRecord(baseURL + '/api/order', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        order: [
          {
            id: '095f0278-badb-4e72-8ddf-4c17f85efa99',
            numberId: 1,
            createdAt: '2021-10-14T13:47:15.983Z',
            updatedAt: '2021-10-14T13:47:15.983Z',
            status: {
              id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
              createdAt: '2021-10-14T09:04:11.797Z',
              updatedAt: '2021-10-14T09:04:11.797Z',
              name: 'accepted',
            },
            waiter: null,
            table: {
              id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
              createdAt: '2021-10-14T13:26:42.813Z',
              updatedAt: '2021-10-14T13:26:42.844Z',
              name: 'Table 3',
              venue: {
                id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
                createdAt: '2021-10-14T13:26:42.697Z',
                updatedAt: '2021-10-14T13:26:42.697Z',
                name: 'Venue 1',
                metadataId: '',
              },
            },
            orderItems: [
              {
                'id': '1ce39f30-3e19-46c2-a111-4e975e94db34',
                'createdAt': '2021-10-21T18:18:42.394Z',
                'updatedAt': '2021-10-21T18:18:42.394Z',
                'status': 'placed',
                'amount': 10.5,
                'menuItem': {
                  'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
                  'createdAt': '2021-10-14T14:02:55.228Z',
                  'updatedAt': '2021-10-14T14:02:55.228Z',
                  'name': 'coca cola',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                'createdAt': '2021-10-14T14:19:23.155Z',
                'updatedAt': '2021-10-14T14:19:23.155Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '02539286-455f-48ba-829a-3ef5ee8f3a7f',
                'createdAt': '2021-10-14T14:20:42.239Z',
                'updatedAt': '2021-10-14T14:20:42.239Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '415110e3-89bb-4bec-be86-d0ba4628d641',
                'createdAt': '2021-10-14T14:21:28.083Z',
                'updatedAt': '2021-10-14T14:21:28.083Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'bebd5c58-c499-4d27-9d5c-e35b7f9eb2d5',
                'createdAt': '2021-10-14T15:08:31.996Z',
                'updatedAt': '2021-10-14T15:08:31.996Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'ff56dc11-c97d-4939-8c34-ff857406af83',
                'createdAt': '2021-10-14T15:09:13.728Z',
                'updatedAt': '2021-10-14T15:09:13.728Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '33e5e6d4-9be8-4cd4-bf47-6ee2bc608a81',
                'createdAt': '2021-10-15T11:20:43.849Z',
                'updatedAt': '2021-10-15T11:28:02.239Z',
                'status': 'inProgress',
                'amount': 10.5,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '0a412403-371e-4a89-8e95-d4450bb5ba51',
                'createdAt': '2021-10-14T14:18:26.567Z',
                'updatedAt': '2021-10-14T14:18:26.567Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '334d390c-ce69-4d62-ad61-7a4680af4a48',
                'createdAt': '2021-10-20T14:47:35.230Z',
                'updatedAt': '2021-10-20T14:52:42.369Z',
                'status': 'inProgress',
                'amount': 10.5,
                'menuItem': {
                  'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
                  'createdAt': '2021-10-14T14:02:55.228Z',
                  'updatedAt': '2021-10-14T14:02:55.228Z',
                  'name': 'coca cola',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
            ],
            amount: {
              orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
              amount: 10.5,
            },
          },
          {
            id: '095f0278-badb-4e72-8ddf-4c17f85efa95',
            numberId: 2,
            createdAt: '2021-10-14T13:47:15.983Z',
            updatedAt: '2021-10-14T13:47:15.983Z',
            status: {
              id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
              createdAt: '2021-10-14T09:04:11.797Z',
              updatedAt: '2021-10-14T09:04:11.797Z',
              name: 'placed',
            },
            waiter: null,
            table: {
              id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
              createdAt: '2021-10-14T13:26:42.813Z',
              updatedAt: '2021-10-14T13:26:42.844Z',
              name: 'Table 2',
              venue: {
                id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
                createdAt: '2021-10-14T13:26:42.697Z',
                updatedAt: '2021-10-14T13:26:42.697Z',
                name: 'Venue 1',
                metadataId: '',
              },
            },
            orderItems: [
              {
                'id': '1ce39f30-3e19-46c2-a111-4e975e94db34',
                'createdAt': '2021-10-21T18:18:42.394Z',
                'updatedAt': '2021-10-21T18:18:42.394Z',
                'status': 'placed',
                'amount': 10.5,
                'menuItem': {
                  'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
                  'createdAt': '2021-10-14T14:02:55.228Z',
                  'updatedAt': '2021-10-14T14:02:55.228Z',
                  'name': 'coca cola',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                'createdAt': '2021-10-14T14:19:23.155Z',
                'updatedAt': '2021-10-14T14:19:23.155Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '02539286-455f-48ba-829a-3ef5ee8f3a7f',
                'createdAt': '2021-10-14T14:20:42.239Z',
                'updatedAt': '2021-10-14T14:20:42.239Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '415110e3-89bb-4bec-be86-d0ba4628d641',
                'createdAt': '2021-10-14T14:21:28.083Z',
                'updatedAt': '2021-10-14T14:21:28.083Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'bebd5c58-c499-4d27-9d5c-e35b7f9eb2d5',
                'createdAt': '2021-10-14T15:08:31.996Z',
                'updatedAt': '2021-10-14T15:08:31.996Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'ff56dc11-c97d-4939-8c34-ff857406af83',
                'createdAt': '2021-10-14T15:09:13.728Z',
                'updatedAt': '2021-10-14T15:09:13.728Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '33e5e6d4-9be8-4cd4-bf47-6ee2bc608a81',
                'createdAt': '2021-10-15T11:20:43.849Z',
                'updatedAt': '2021-10-15T11:28:02.239Z',
                'status': 'inProgress',
                'amount': 10.5,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '0a412403-371e-4a89-8e95-d4450bb5ba51',
                'createdAt': '2021-10-14T14:18:26.567Z',
                'updatedAt': '2021-10-14T14:18:26.567Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '334d390c-ce69-4d62-ad61-7a4680af4a48',
                'createdAt': '2021-10-20T14:47:35.230Z',
                'updatedAt': '2021-10-20T14:52:42.369Z',
                'status': 'inProgress',
                'amount': 10.5,
                'menuItem': {
                  'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
                  'createdAt': '2021-10-14T14:02:55.228Z',
                  'updatedAt': '2021-10-14T14:02:55.228Z',
                  'name': 'coca cola',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
            ],
            amount: {
              orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
              amount: 10.5,
            },
          },
          {
            id: '095f0278-badb-4e72-8ddf-4c17f85efa97',
            createdAt: '2021-10-14T13:47:15.983Z',
            updatedAt: '2021-10-14T13:47:15.983Z',
            status: {
              id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
              createdAt: '2021-10-14T09:04:11.797Z',
              updatedAt: '2021-10-14T09:04:11.797Z',
              name: 'accepted',
            },
            waiter: null,
            table: {
              id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
              numberId: 3,
              createdAt: '2021-10-14T13:26:42.813Z',
              updatedAt: '2021-10-14T13:26:42.844Z',
              name: 'Table 1',
              venue: {
                id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
                createdAt: '2021-10-14T13:26:42.697Z',
                updatedAt: '2021-10-14T13:26:42.697Z',
                name: 'Venue 1',
                metadataId: '',
              },
            },
            orderItems: [
              {
                id: '0a412403-371e-4a89-8e95-d4450bb5ba51',
                createdAt: '2021-10-14T14:18:26.567Z',
                updatedAt: '2021-10-14T14:18:26.567Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
            ],
            amount: {
              orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
              amount: 10.5,
            },
          },
        ],
        count: 5,
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL + '/api/order?status=completed', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        orders: [
          {
            id: '095f0278-badb-4e72-8ddf-4c17f85efa91',
            numberId: 1,
            createdAt: '2021-10-15T13:47:15.983Z',
            updatedAt: '2021-10-14T13:47:15.983Z',
            status: {
              id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
              createdAt: '2021-10-14T09:04:11.797Z',
              updatedAt: '2021-10-14T09:04:11.797Z',
              name: 'completed',
            },
            waiter: null,
            table: {
              id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
              createdAt: '2021-10-14T13:26:42.813Z',
              updatedAt: '2021-10-14T13:26:42.844Z',
              name: 'Table 3',
              venue: {
                id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
                createdAt: '2021-10-14T13:26:42.697Z',
                updatedAt: '2021-10-14T13:26:42.697Z',
                name: 'Venue 1',
                metadataId: '',
              },
            },
            orderItems: [
              {
                'id': '1ce39f30-3e19-46c2-a111-4e975e94db34',
                'createdAt': '2021-10-21T18:18:42.394Z',
                'updatedAt': '2021-10-21T18:18:42.394Z',
                'status': 'placed',
                'amount': 10.5,
                'menuItem': {
                  'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
                  'createdAt': '2021-10-14T14:02:55.228Z',
                  'updatedAt': '2021-10-14T14:02:55.228Z',
                  'name': 'coca cola',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                'createdAt': '2021-10-14T14:19:23.155Z',
                'updatedAt': '2021-10-14T14:19:23.155Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '02539286-455f-48ba-829a-3ef5ee8f3a7f',
                'createdAt': '2021-10-14T14:20:42.239Z',
                'updatedAt': '2021-10-14T14:20:42.239Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '415110e3-89bb-4bec-be86-d0ba4628d641',
                'createdAt': '2021-10-14T14:21:28.083Z',
                'updatedAt': '2021-10-14T14:21:28.083Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'bebd5c58-c499-4d27-9d5c-e35b7f9eb2d5',
                'createdAt': '2021-10-14T15:08:31.996Z',
                'updatedAt': '2021-10-14T15:08:31.996Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'ff56dc11-c97d-4939-8c34-ff857406af83',
                'createdAt': '2021-10-14T15:09:13.728Z',
                'updatedAt': '2021-10-14T15:09:13.728Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '33e5e6d4-9be8-4cd4-bf47-6ee2bc608a81',
                'createdAt': '2021-10-15T11:20:43.849Z',
                'updatedAt': '2021-10-15T11:28:02.239Z',
                'status': 'inProgress',
                'amount': 10.5,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '0a412403-371e-4a89-8e95-d4450bb5ba51',
                'createdAt': '2021-10-14T14:18:26.567Z',
                'updatedAt': '2021-10-14T14:18:26.567Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '334d390c-ce69-4d62-ad61-7a4680af4a48',
                'createdAt': '2021-10-20T14:47:35.230Z',
                'updatedAt': '2021-10-20T14:52:42.369Z',
                'status': 'inProgress',
                'amount': 10.5,
                'menuItem': {
                  'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
                  'createdAt': '2021-10-14T14:02:55.228Z',
                  'updatedAt': '2021-10-14T14:02:55.228Z',
                  'name': 'coca cola',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
            ],
            amount: {
              orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
              amount: 10.5,
            },
          },
          {
            id: '095f0278-badb-4e72-8ddf-4c17f85efa92',
            numberId: 2,
            createdAt: '2021-10-14T13:47:15.983Z',
            updatedAt: '2021-10-14T13:47:15.983Z',
            status: {
              id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
              createdAt: '2021-10-14T09:04:11.797Z',
              updatedAt: '2021-10-14T09:04:11.797Z',
              name: 'completed',
            },
            waiter: null,
            table: {
              id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
              createdAt: '2021-10-14T13:26:42.813Z',
              updatedAt: '2021-10-14T13:26:42.844Z',
              name: 'Table 2',
              venue: {
                id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
                createdAt: '2021-10-14T13:26:42.697Z',
                updatedAt: '2021-10-14T13:26:42.697Z',
                name: 'Venue 1',
                metadataId: '',
              },
            },
            orderItems: [
              {
                id: '0a412403-371e-4a89-8e95-d4450bb5ba51',
                createdAt: '2021-10-14T14:18:26.567Z',
                updatedAt: '2021-10-14T14:18:26.567Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
            ],
            amount: {
              orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
              amount: 10.5,
            },
          },
          {
            id: '095f0278-badb-4e72-8ddf-4c17f85efa93',
            numberId: 3,
            createdAt: '2021-10-13T13:47:15.983Z',
            updatedAt: '2021-10-14T13:47:15.983Z',
            status: {
              id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
              createdAt: '2021-10-14T09:04:11.797Z',
              updatedAt: '2021-10-14T09:04:11.797Z',
              name: 'completed',
            },
            waiter: null,
            table: {
              id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
              createdAt: '2021-10-14T13:26:42.813Z',
              updatedAt: '2021-10-14T13:26:42.844Z',
              name: 'Table 1',
              venue: {
                id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
                createdAt: '2021-10-14T13:26:42.697Z',
                updatedAt: '2021-10-14T13:26:42.697Z',
                name: 'Venue 1',
                metadataId: '',
              },
            },
            orderItems: [
              {
                'id': '1ce39f30-3e19-46c2-a111-4e975e94db34',
                'createdAt': '2021-10-21T18:18:42.394Z',
                'updatedAt': '2021-10-21T18:18:42.394Z',
                'status': 'placed',
                'amount': 10.5,
                'menuItem': {
                  'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
                  'createdAt': '2021-10-14T14:02:55.228Z',
                  'updatedAt': '2021-10-14T14:02:55.228Z',
                  'name': 'coca cola',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                'createdAt': '2021-10-14T14:19:23.155Z',
                'updatedAt': '2021-10-14T14:19:23.155Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '02539286-455f-48ba-829a-3ef5ee8f3a7f',
                'createdAt': '2021-10-14T14:20:42.239Z',
                'updatedAt': '2021-10-14T14:20:42.239Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '415110e3-89bb-4bec-be86-d0ba4628d641',
                'createdAt': '2021-10-14T14:21:28.083Z',
                'updatedAt': '2021-10-14T14:21:28.083Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'bebd5c58-c499-4d27-9d5c-e35b7f9eb2d5',
                'createdAt': '2021-10-14T15:08:31.996Z',
                'updatedAt': '2021-10-14T15:08:31.996Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': 'ff56dc11-c97d-4939-8c34-ff857406af83',
                'createdAt': '2021-10-14T15:09:13.728Z',
                'updatedAt': '2021-10-14T15:09:13.728Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '33e5e6d4-9be8-4cd4-bf47-6ee2bc608a81',
                'createdAt': '2021-10-15T11:20:43.849Z',
                'updatedAt': '2021-10-15T11:28:02.239Z',
                'status': 'inProgress',
                'amount': 10.5,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '0a412403-371e-4a89-8e95-d4450bb5ba51',
                'createdAt': '2021-10-14T14:18:26.567Z',
                'updatedAt': '2021-10-14T14:18:26.567Z',
                'status': 'placed',
                'amount': null,
                'menuItem': {
                  'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
                  'createdAt': '2021-10-14T14:03:16.342Z',
                  'updatedAt': '2021-10-14T14:03:16.342Z',
                  'name': 'pepsi',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
              {
                'id': '334d390c-ce69-4d62-ad61-7a4680af4a48',
                'createdAt': '2021-10-20T14:47:35.230Z',
                'updatedAt': '2021-10-20T14:52:42.369Z',
                'status': 'inProgress',
                'amount': 10.5,
                'menuItem': {
                  'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
                  'createdAt': '2021-10-14T14:02:55.228Z',
                  'updatedAt': '2021-10-14T14:02:55.228Z',
                  'name': 'coca cola',
                  'description': 'The best coca cola',
                  'price': 10.5,
                  'available': true,
                  'media': '',
                },
              },
            ],
            amount: {
              orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
              amount: 10.5,
            },
          },
        ],
        count: 5,
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL + '/api/order?myOrders=true&status=accepted', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        orders: [
          {
            id: '095f0278-badb-4e72-8ddf-4c17f85efa91',
            numberId: 1,
            createdAt: '2021-10-14T13:47:15.983Z',
            updatedAt: '2021-10-14T13:47:15.983Z',
            status: {
              id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
              createdAt: '2021-10-14T09:04:11.797Z',
              updatedAt: '2021-10-14T09:04:11.797Z',
              name: 'accepted',
            },
            waiter: null,
            table: {
              id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
              createdAt: '2021-10-14T13:26:42.813Z',
              updatedAt: '2021-10-14T13:26:42.844Z',
              name: 'Table 3',
              venue: {
                id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
                createdAt: '2021-10-14T13:26:42.697Z',
                updatedAt: '2021-10-14T13:26:42.697Z',
                name: 'Venue 1',
                metadataId: '',
              },
            },
            orderItems: [
              {
                id: '0a412403-371e-4a89-8e95-d4450bb5ba51',
                createdAt: '2021-10-14T14:18:26.567Z',
                updatedAt: '2021-10-14T14:18:26.567Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
            ],
            amount: {
              orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
              amount: 10.5,
            },
          },
          {
            id: '095f0278-badb-4e72-8ddf-4c17f85efa92',
            numberId: 2,
            createdAt: '2021-10-14T13:47:15.983Z',
            updatedAt: '2021-10-14T13:47:15.983Z',
            status: {
              id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
              createdAt: '2021-10-14T09:04:11.797Z',
              updatedAt: '2021-10-14T09:04:11.797Z',
              name: 'accepted',
            },
            waiter: null,
            table: {
              id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
              createdAt: '2021-10-14T13:26:42.813Z',
              updatedAt: '2021-10-14T13:26:42.844Z',
              name: 'Table 2',
              venue: {
                id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
                createdAt: '2021-10-14T13:26:42.697Z',
                updatedAt: '2021-10-14T13:26:42.697Z',
                name: 'Venue 1',
                metadataId: '',
              },
            },
            orderItems: [
              {
                id: '0a412403-371e-4a89-8e95-d4450bb5ba51',
                createdAt: '2021-10-14T14:18:26.567Z',
                updatedAt: '2021-10-14T14:18:26.567Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
            ],
            amount: {
              orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
              amount: 10.5,
            },
          },
          {
            id: '095f0278-badb-4e72-8ddf-4c17f85efa93',
            numberId: 3,
            createdAt: '2021-10-14T13:47:15.983Z',
            updatedAt: '2021-10-14T13:47:15.983Z',
            status: {
              id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
              createdAt: '2021-10-14T09:04:11.797Z',
              updatedAt: '2021-10-14T09:04:11.797Z',
              name: 'accepted',
            },
            waiter: null,
            table: {
              id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
              createdAt: '2021-10-14T13:26:42.813Z',
              updatedAt: '2021-10-14T13:26:42.844Z',
              name: 'Table 1',
              venue: {
                id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
                createdAt: '2021-10-14T13:26:42.697Z',
                updatedAt: '2021-10-14T13:26:42.697Z',
                name: 'Venue 1',
                metadataId: '',
              },
            },
            orderItems: [
              {
                id: '0a412403-371e-4a89-8e95-d4450bb5ba51',
                createdAt: '2021-10-14T14:18:26.567Z',
                updatedAt: '2021-10-14T14:18:26.567Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
              {
                id: 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
                createdAt: '2021-10-14T14:19:23.155Z',
                updatedAt: '2021-10-14T14:19:23.155Z',
                status: 'placed',
                amount: null,
              },
            ],
            amount: {
              orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
              amount: 10.5,
            },
          },
        ],
        count: 5,
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        token: 'eyJhbGciOiJ',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL + '/api/order/change-status/095f0278-badb-4e72-8ddf-4c17f85efa99', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        test: 'get',
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        id: '095f0278-badb-4e72-8ddf-4c17f85efa99',
        numberId: 1,
        createdAt: '2021-10-14T13:47:15.983Z',
        updatedAt: '2021-10-14T13:47:15.983Z',
        status: {
          id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
          createdAt: '2021-10-14T09:04:11.797Z',
          updatedAt: '2021-10-14T09:04:11.797Z',
          name: 'accepted',
        },
        waiter: null,
        table: {
          id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
          createdAt: '2021-10-14T13:26:42.813Z',
          updatedAt: '2021-10-14T13:26:42.844Z',
          name: 'Table 3',
          venue: {
            id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
            createdAt: '2021-10-14T13:26:42.697Z',
            updatedAt: '2021-10-14T13:26:42.697Z',
            name: 'Venue 1',
            metadataId: '',
          },
        },
        orderItems: [
          {
            'id': '1ce39f30-3e19-46c2-a111-4e975e94db34',
            'createdAt': '2021-10-21T18:18:42.394Z',
            'updatedAt': '2021-10-21T18:18:42.394Z',
            'status': 'placed',
            'amount': 10.5,
            'menuItem': {
              'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
              'createdAt': '2021-10-14T14:02:55.228Z',
              'updatedAt': '2021-10-14T14:02:55.228Z',
              'name': 'coca cola',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
            'createdAt': '2021-10-14T14:19:23.155Z',
            'updatedAt': '2021-10-14T14:19:23.155Z',
            'status': 'placed',
            'amount': null,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': '02539286-455f-48ba-829a-3ef5ee8f3a7f',
            'createdAt': '2021-10-14T14:20:42.239Z',
            'updatedAt': '2021-10-14T14:20:42.239Z',
            'status': 'placed',
            'amount': null,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': '415110e3-89bb-4bec-be86-d0ba4628d641',
            'createdAt': '2021-10-14T14:21:28.083Z',
            'updatedAt': '2021-10-14T14:21:28.083Z',
            'status': 'placed',
            'amount': null,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': 'bebd5c58-c499-4d27-9d5c-e35b7f9eb2d5',
            'createdAt': '2021-10-14T15:08:31.996Z',
            'updatedAt': '2021-10-14T15:08:31.996Z',
            'status': 'placed',
            'amount': null,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': 'ff56dc11-c97d-4939-8c34-ff857406af83',
            'createdAt': '2021-10-14T15:09:13.728Z',
            'updatedAt': '2021-10-14T15:09:13.728Z',
            'status': 'placed',
            'amount': null,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': '33e5e6d4-9be8-4cd4-bf47-6ee2bc608a81',
            'createdAt': '2021-10-15T11:20:43.849Z',
            'updatedAt': '2021-10-15T11:28:02.239Z',
            'status': 'inProgress',
            'amount': 10.5,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': '0a412403-371e-4a89-8e95-d4450bb5ba51',
            'createdAt': '2021-10-14T14:18:26.567Z',
            'updatedAt': '2021-10-14T14:18:26.567Z',
            'status': 'placed',
            'amount': null,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': '334d390c-ce69-4d62-ad61-7a4680af4a48',
            'createdAt': '2021-10-20T14:47:35.230Z',
            'updatedAt': '2021-10-20T14:52:42.369Z',
            'status': 'inProgress',
            'amount': 10.5,
            'menuItem': {
              'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
              'createdAt': '2021-10-14T14:02:55.228Z',
              'updatedAt': '2021-10-14T14:02:55.228Z',
              'name': 'coca cola',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
        ],
        amount: {
          orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
          amount: 10.5,
        },
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
  MockDB.connection().addRecord(baseURL + '/api/order/single', {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        id: '095f0278-badb-4e72-8ddf-4c17f85efa93',
        numberId: 1,
        createdAt: '2021-10-14T13:47:15.983Z',
        updatedAt: '2021-10-14T13:47:15.983Z',
        status: {
          id: '54e6123c-3b71-4ebe-8494-213cfe52f4e7',
          createdAt: '2021-10-14T09:04:11.797Z',
          updatedAt: '2021-10-14T09:04:11.797Z',
          name: 'accepted',
        },
        waiter: null,
        table: {
          id: 'd7fb838a-2ea5-4f1b-9cc8-aaa55c662bbd',
          createdAt: '2021-10-14T13:26:42.813Z',
          updatedAt: '2021-10-14T13:26:42.844Z',
          name: 'Table 1',
          venue: {
            id: '94bb6109-c523-44cd-a1e1-9c159601d8fb',
            createdAt: '2021-10-14T13:26:42.697Z',
            updatedAt: '2021-10-14T13:26:42.697Z',
            name: 'Venue 1',
            metadataId: '',
          },
        },
        orderItems: [
          {
            id: '1ce39f30-3e19-46c2-a111-4e975e94db34',
            createdAt: '2021-10-21T18:18:42.394Z',
            updatedAt: '2021-10-21T18:18:42.394Z',
            status: 'delivered',
            amount: 10.5,
            menuItem: {
              id: '2db6d812-0b9a-4c47-b665-6d782a325516',
              createdAt: '2021-10-14T14:02:55.228Z',
              updatedAt: '2021-10-14T14:02:55.228Z',
              name: 'coca cola',
              description: 'The best coca cola',
              price: 10.5,
              available: true,
              media: '',
            },
          },
          {
            'id': 'de92db96-3e3d-4f5a-a94e-2e417caf990b',
            'createdAt': '2021-10-14T14:19:23.155Z',
            'updatedAt': '2021-10-14T14:19:23.155Z',
            'status': 'delivered',
            'amount': 10.5,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': '02539286-455f-48ba-829a-3ef5ee8f3a7f',
            'createdAt': '2021-10-14T14:20:42.239Z',
            'updatedAt': '2021-10-14T14:20:42.239Z',
            'status': 'delivered',
            'amount': 10.5,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': '415110e3-89bb-4bec-be86-d0ba4628d641',
            'createdAt': '2021-10-14T14:21:28.083Z',
            'updatedAt': '2021-10-14T14:21:28.083Z',
            'status': 'delivered',
            'amount': 10.5,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': 'bebd5c58-c499-4d27-9d5c-e35b7f9eb2d5',
            'createdAt': '2021-10-14T15:08:31.996Z',
            'updatedAt': '2021-10-14T15:08:31.996Z',
            'status': 'delivered',
            'amount': 10.5,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': 'ff56dc11-c97d-4939-8c34-ff857406af83',
            'createdAt': '2021-10-14T15:09:13.728Z',
            'updatedAt': '2021-10-14T15:09:13.728Z',
            'status': 'delivered',
            'amount': 10.5,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': '33e5e6d4-9be8-4cd4-bf47-6ee2bc608a81',
            'createdAt': '2021-10-15T11:20:43.849Z',
            'updatedAt': '2021-10-15T11:28:02.239Z',
            'status': 'delivered',
            'amount': 10.5,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': '0a412403-371e-4a89-8e95-d4450bb5ba51',
            'createdAt': '2021-10-14T14:18:26.567Z',
            'updatedAt': '2021-10-14T14:18:26.567Z',
            'status': 'delivered',
            'amount': 10.5,
            'menuItem': {
              'id': 'b53835ce-57a9-4b64-a7a6-68a5185263d2',
              'createdAt': '2021-10-14T14:03:16.342Z',
              'updatedAt': '2021-10-14T14:03:16.342Z',
              'name': 'pepsi',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
          {
            'id': '334d390c-ce69-4d62-ad61-7a4680af4a48',
            'createdAt': '2021-10-20T14:47:35.230Z',
            'updatedAt': '2021-10-20T14:52:42.369Z',
            'status': 'delivered',
            'amount': 10.5,
            'menuItem': {
              'id': '2db6d812-0b9a-4c47-b665-6d782a325516',
              'createdAt': '2021-10-14T14:02:55.228Z',
              'updatedAt': '2021-10-14T14:02:55.228Z',
              'name': 'coca cola',
              'description': 'The best coca cola',
              'price': 10.5,
              'available': true,
              'media': '',
            },
          },
        ],
        amount: {
          orderId: '095f0278-badb-4e72-8ddf-4c17f85efa99',
          amount: 94.5,
        },
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
}


