import React, { FC, useState } from 'react';
import { PageTitle } from '../common/components/page-title';
import { auth } from '../common/services/auth.service';
import { OrderTabs } from './components/order-tabs';
import { OrdersHistory } from './components/orders-history';
import { MyOrders } from './components/orders-myorders';
import { OrdersOngoing } from './components/orders-ongoing';
import { Styled } from './orders.styled';
import { IOrdersTab } from './types';

export const Orders:FC = () => {
  const [currentTab, setCurrentTab] = useState<IOrdersTab>('orders');

  const tabs = auth.isWaiter() ? {
    'orders': <OrdersOngoing/>,
    'history': <OrdersHistory/>,
    'myOrders': <MyOrders/>,
  } : {
    'orders': <OrdersOngoing/>,
    'history': <OrdersHistory/>,
  };

  return (
    <>
      <Styled.Wrapper>
        <PageTitle>ONGOING ORDERS</PageTitle>
        <OrderTabs
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}/>
      </Styled.Wrapper>

      {tabs[currentTab]}
    </>
  );
};
