import React, { FC, useEffect, useState } from 'react';
import { INotificationResponse, IOrder } from '../orders/types';
import { modalTypes, socketEvents } from '../common/constants';
import { socketService } from '../common/services/socket-service';
import { RequestedBillModal } from './components/requested-bill';
import { TableCallModal } from './components/table-call';
import { OrderUpdateModal } from './components/order-update';
import { getModalStateObject } from '../../navigation/utils/getModalState';

export const Notifications: FC = () => {
  const [displayedModal, setDisplayedModal] = useState(getModalStateObject());

  const onClose = () => {
    setDisplayedModal(getModalStateObject());
  };

  const changeDisplayedModal = (modalType: modalTypes, tableName: string, paymentMethod: string) => {
    setDisplayedModal(getModalStateObject(modalType, tableName, paymentMethod));
  };

  const onChangeOrder = (msg: IOrder | INotificationResponse) => {
    changeDisplayedModal(
        modalTypes.orderUpdate,
        msg.table.name,
        '',
    );
  };

  const onCallWaiter = (msg: IOrder | INotificationResponse) => {
    changeDisplayedModal(
        modalTypes.callWaiter,
        msg.table.name,
        '',
    );
  };

  const onRequestBill = (msg: INotificationResponse) => {
    changeDisplayedModal(
        modalTypes.requestBill,
        msg.table.name,
        msg.paymentMethod,
    );
  };

  useEffect(() => {
    socketService.socket?.emit('order');
    socketService.socket?.on(socketEvents.changeOrder, onChangeOrder);
    socketService.socket?.on(socketEvents.callWaiter, onCallWaiter);
    socketService.socket?.on(socketEvents.requestBill, onRequestBill);

    return () => {
      socketService.socket?.off(socketEvents.changeOrder, onChangeOrder);
      socketService.socket?.off(socketEvents.callWaiter, onCallWaiter);
      socketService.socket?.off(socketEvents.requestBill, onRequestBill);
    };
  }, []);

  const modals = {
    [modalTypes.requestBill]:
      <RequestedBillModal
        isOpen={true}
        onRequestClose={onClose}
        tableName={displayedModal.tableName}
        paymentMethod={displayedModal.paymentMethod}
      />,
    [modalTypes.callWaiter]:
      <TableCallModal
        isOpen={true}
        onRequestClose={onClose}
        tableName={displayedModal.tableName}
      />,
    [modalTypes.orderUpdate]:
      <OrderUpdateModal
        isOpen={true}
        onRequestClose={onClose}
        tableName={displayedModal.tableName}
      />,
    [modalTypes.none]: null,
  };

  return (<>
    {modals[displayedModal.modalType]}
  </>);
};
