import * as Yup from 'yup';
import { getNameValidator } from '../../common/utils/get-name-validator';
import { getPriceValidator } from '../../common/utils/get-price-validator';

export const validationSchema = Yup.object().shape({
  name: getNameValidator(),
  description: Yup.string()
      .required('Description is required.')
      .strict(true)
      .trim('Spaces before and after text is not allowed')
      .min(3, 'Description is too short.')
      .max(300, 'Description is too long'),
  media: Yup.string(),
  price: getPriceValidator(),
});
