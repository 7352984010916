import styled from 'styled-components';

const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Dropzone = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  height: 160px;
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spaces.xs};
  box-sizing: border-box;
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.xs};
  color: ${({ theme }) => theme.colors.black};
`;

export const Styled = {
  Dropzone,
  Text,
  DropzoneContainer,
};
