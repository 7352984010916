import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { IUser } from '../../../../types';
import { PageTitle } from '../../../common/components/page-title';
import { addWaiterService } from '../../services/add-waiter.service';
import { IWaiterModeComponent } from '../../types';
import { AddWaiterCard } from '../add-waiter-card';
import { WaiterCard } from '../waiter-card';
import { Styled } from './waiter-list.styled';

interface IWaiterListProps extends IWaiterModeComponent {
  setCurrentWaiter: (waiter: IUser) => void,
}

export const WaiterList:FC<IWaiterListProps> = ({ setMode, setCurrentWaiter }) => {
  const fetchWaiters = async () => {
    return await addWaiterService.fetchUsers();
  };
  const onAddWaiterClickHandler = () => {
    setMode('add');
  };

  const { isLoading, data } = useQuery('waiters', fetchWaiters);

  const waitersCards = data?.map((waiter) => {
    return <WaiterCard
      key={waiter.id}
      waiter={waiter}
      setMode={setMode}
      setCurrentWaiter={setCurrentWaiter}
    />;
  });

  return (
    <>
      <PageTitle>Add waiter</PageTitle>
      {isLoading ? 'Loading...' :
      (
      <Styled.WaiterList>
        <AddWaiterCard onClick={onAddWaiterClickHandler}/>
        {waitersCards}
      </Styled.WaiterList>
      )
      }
    </>
  );
};
