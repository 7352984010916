import styled from 'styled-components';

const Title = styled.h1`
    font-weight: 500;
    text-transform: uppercase;
    font-size: 24px;
    margin: 0 0 24px;
`;

const HomeWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spaces.lg};
`;
const InfoRow = styled.div`
    font-size: 16px;
    margin-bottom: 3px;
`;
export const Styled = {
  Title,
  HomeWrapper,
  InfoRow,
};
