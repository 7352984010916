import React, { FC } from 'react';
import { dateService } from '../../../../common/services/date.service';
import { ICell } from '../../../../common/types';
import { Styled } from './text-cell.styled';

export const CreatedAtCell:FC<ICell> = ({ item }) => {
  return (
    <Styled.TextCell>
      {dateService.stringToLocaleDate(item.createdAt)}
    </Styled.TextCell>
  );
};
