import React, { FC } from 'react';
import { NotificationModal } from '../../../common/components/notification-modal';

interface IOrderUpdateModalProps {
  isOpen: boolean;
  onRequestClose: (event: React.MouseEvent) => void;
  tableName: string;
};

export const OrderUpdateModal: FC<IOrderUpdateModalProps> = ({
  isOpen,
  onRequestClose,
  tableName,
}) => {
  const title = `Order for ${tableName} has been changed`;
  return (
    <NotificationModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
    />
  );
};
