import { ROUTES } from '../modules/common/constants';
import { MockDB } from '../modules/common/services/mock-http.service';
import { HttpMethods } from '../types';

export default function(baseURL: string) {
  MockDB.connection().addRecord(baseURL + `/api/${ROUTES.PROFILE}`, {
    [HttpMethods.GET]: {
      status: 200,
      data: {
        id: '1',
        email: 'ow1@gmail.com',
        name: 'Ovner Venue 1',
        venue: {
          id: '1',
          createdAt: '2021-10-14T13:26:42.697Z',
          updatedAt: '2021-10-14T13:26:42.697Z',
          name: 'Venue 1',
          metadataId: '',
        },
        role: ['venueOwner'],
        iat: 1634743991,
        exp: 1634830391,
      },
    },
    [HttpMethods.POST]: {
      status: 200,
      data: {
        test: 'post',
      },
    },
    [HttpMethods.PUT]: {
      status: 200,
      data: {
        test: 'put',
      },
    },
    [HttpMethods.DELETE]: {
      status: 200,
      data: {
        test: 'delete',
      },
    },
  });
}
