import styled from 'styled-components';

const Summary = styled.div`
  font-size: ${({ theme }) => theme.fonts.xl};
  font-weight: 500;
  margin-top: ${({ theme }) => theme.spaces.xl};
  &>span:first-child{
    margin-right: ${({ theme }) => theme.spaces.lg};
  }
`;

export const Styled = {
  Summary,
};
