import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../../common/components/button';
import { Styled } from './login-select.styled';

export const LoginSelect:FC = () => {
  const history = useHistory();
  const redirectTo = (userRole: string) => history.push(`/login/${userRole}`);

  return (
    <>
      <Styled.Subtitle>
        To get started, select a user:
      </Styled.Subtitle>
      <Styled.ButtonContainer>
        <Button
          background="primary"
          rounded={true}
          onClick={() => redirectTo('owner')}
        >I’m an OWNER</Button>
        <Button
          background="primary"
          rounded={true}
          onClick={() => redirectTo('waiter')}
        >I’m a WAITER</Button>
      </Styled.ButtonContainer>
    </>
  );
};
