import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface IWithImg {
  src: string,
  activeSrc: string,
}

const SideBarSection = styled.div`
  box-sizing: border-box;
  height: 100vh;
  min-width: 111px;
  max-width: 111px;
  padding-top: ${({ theme }) => theme.spaces.sm};
  padding-bottom: ${({ theme }) => theme.spaces.md};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

const CurrentUserRole = styled.span`
  font-size: ${({ theme }) => theme.fonts.md};
  font-family: Roboto;
  margin-bottom: ${({ theme }) => theme.spaces.sm};
  color: ${({ theme }) => theme.colors.white};
`;

const NavMenu = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const Link = styled(NavLink)<IWithImg>`
  width: 100%;
  font-size: ${({ theme }) => theme.fonts.sm};
  text-decoration: none;
  padding: ${({ theme }) => theme.spaces.xs} 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  & div {
    width:43px;
    height:43px;
    background-image: url('${({ src }) => src}')
  }
  &.active div, &:hover div{
    background-image: url('${({ activeSrc }) => activeSrc}')
  }
  &.active, &:hover {
    background-color: ${({ theme }) => theme.colors.background}; 
    color:${({ theme }) => theme.colors.primary}
  }
`;
const LogoutLink = styled.span`
  width: 100%;
  font-size: ${({ theme }) => theme.fonts.sm};
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  padding: ${({ theme }) => theme.spaces.xs} 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.active, &:hover {
    background-color: ${({ theme }) => theme.colors.secondary}; 
    color:${({ theme }) => theme.colors.white} 
  }
`;

const MenuIcon = styled.img`
  width:43px;
  height:43px;
`;

const Logo = styled.img`
  width: 69px;
  height: 32px;
  margin-bottom: ${({ theme }) => theme.spaces.sm};
`;

export const Styled ={
  SideBarSection,
  CurrentUserRole,
  NavMenu,
  Link,
  MenuIcon,
  MenuWrapper,
  LogoutLink,
  Logo,
};
