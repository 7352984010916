import React, { FC } from 'react';
import { WaiterForm } from '../waiter-form';
import { PageTitle } from '../../../common/components/page-title';
import { ArrowBack } from '../../../common/components/arrow-back';
import { IWaiterModeComponent } from '../../types';

export const WaiterAdd:FC<IWaiterModeComponent> = ({ setMode }) => {
  const onArrowClickHandler = () => {
    setMode('list');
  };

  return (
    <>
      <PageTitle>ADD NEW WAITER</PageTitle>
      <ArrowBack onClick={onArrowClickHandler}/>
      <WaiterForm
        currentWaiter={null}
        setMode={setMode}
      />
    </>
  );
};
