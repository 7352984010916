import styled from 'styled-components';

const TableStatus = styled.div`
  width: 100%;
  text-align: start;
  font-size: ${({ theme }) => theme.fonts.md};
  position: relative;
  text-transform: capitalize;

`;

const SelectWrapper = styled.div`
  position: relative;
  width: 160px;
  display: flex;
  align-items: center;
`;

const Status = styled.div`
  margin-left: ${({ theme }) => theme.spaces.xs};
`;

export const Styled = {
  TableStatus,
  SelectWrapper,
  Status,
};
