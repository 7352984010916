import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { auth } from '../modules/common/services/auth.service';
import { RouteProps } from 'react-router-dom';

export interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<RouteProps>;
};

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component } = props;

  return (
    <Route
      render={(routeProps) => auth.isAuthorized() ?
      <Component {...routeProps} /> :
      <Redirect to='/login' />
      }
      path={props.path}
    />
  );
};
