import styled from 'styled-components';

const InputWrapper = styled.div<{ isEdit?: boolean}>`
  margin-bottom: ${({ theme, isEdit }) => isEdit? '0' : theme.spaces.sm};
  position: relative;
`;
const Label = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spaces.xxs};
`;
const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spaces.sm} ${({ theme }) => theme.spaces.xs};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 10px;
  &::placeholder{
    color: ${({ theme }) => theme.colors.black}
  }
`;
export const Styled = {
  InputWrapper,
  Input,
  Label,
};
