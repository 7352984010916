import { IUser } from '../../../types';
import { HttpServiceFactory } from '../../common/services';
import { EnhancedWithAuthHttpService } from '../../common/services/http-auth.service';
import { IFormData, IUpdateUserFormData } from '../types';


export class AddWaiterService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}
  public registerUser(name: string, email: string, password: string) {
    return this.httpService.post<IUser, IFormData>('waiter-auth/registration', { name, email, password });
  }
  public fetchUsers() {
    return this.httpService.get<IUser[]>('waiter/');
  }
  public fetchUser(id: string) {
    return this.httpService.get<IUser>(`waiter/${id}`);
  }
  public updateUser(id:string, name: string, email: string, password: string = '') {
    const FormData: IUpdateUserFormData = { name, email };
    password ? FormData.password = password : null;
    return this.httpService.put<IUser, IUpdateUserFormData>(`waiter/${id}`, FormData );
  }
  public deleteUser(id:string) {
    return this.httpService.delete<IUser>(`waiter/${id}`);
  }
};

const factory = new HttpServiceFactory();
export const addWaiterService = new AddWaiterService(factory.createAuthHttpService());
