import React from 'react';
import { useQuery } from 'react-query';
import { roleNames, storeKeys } from '../../../common/constants';
import { userService } from '../../../common/services/user.service';
import { Styled } from './home.styled';

export const Home = () => {
  const { data, isLoading } = useQuery('profile', () => userService.getProfile(), {
    onSuccess: (data) => {
      data && localStorage.setItem(storeKeys.profile, JSON.stringify(data));
    },
    onError: () => {
      localStorage.removeItem(storeKeys.profile);
    },
  });

  return !isLoading ? (
    <Styled.HomeWrapper>
      <Styled.Title>
        {roleNames[data?.role?.[0]!]} profile
      </Styled.Title>
      <Styled.InfoRow>
        Full name: {data?.name}
      </Styled.InfoRow>
      <Styled.InfoRow>
        E-mail: {data?.email}
      </Styled.InfoRow>
    </Styled.HomeWrapper>
  ) : <h2>Loading...</h2>;
};
