import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import qs from 'qs';
import { Login } from '../modules/login';
import { ErrorPage } from '../modules/error';
import { MainContent } from './components/main-content';
import { Notifications } from '../modules/notifications';

interface IMainRouterProps{
  isAuth: boolean,
  setIsAuth: (isAuth: boolean) => void
}

export const MainRouter: FC<IMainRouterProps> = ({ isAuth, setIsAuth }) => {
  document.addEventListener('http-error',
      ({ detail }: CustomEventInit) => {
        window.location.href=`/error?${qs.stringify(detail)}`;
      },
  );

  const routes = isAuth ? (
    <>
      <Route path="/">
        <MainContent/>
      </Route>
      <Route path="/login" >
        <Redirect to="/"/>
      </Route>
      <Notifications/>
    </>
  ) : (
    <>
      <Route path="/login">
        <Login setIsAuth={setIsAuth}/>
      </Route>
      <Route exact path="/error" component={ErrorPage}/>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route path="/login/:random/:random">
        <ErrorPage />
      </Route>
    </>
  );

  return (
    <Router>
      <Switch>
        {routes}
      </Switch>
    </Router>
  );
};
