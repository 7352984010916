import { useMutation, useQueryClient } from 'react-query';
import { ordersService } from '../services/orders.service';


export const useChangeStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(( { id, status }: any) =>
    ordersService.changeOrderStatus(id, status), {
    onSuccess: () => {
      queryClient.invalidateQueries('completedOrders');
      queryClient.invalidateQueries('myOrders');
    },
  });
};

