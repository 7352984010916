import { IFormData } from '../types';

export const initialValues:IFormData = {
  name: '',
  email: '',
  password: '',
};

export const PASSWORD_MATCH =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&*])(?=.{8,})/;
