import styled from 'styled-components';
import arrowLeft from '../../assets/arrow-left.png';

const Wrapper = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TopButtonsWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 0px ${({ theme }) => theme.spaces.lg};
  &>button{
    width: 150px;
  }
`;

const BackButton = styled.div`
  background-image: url(${arrowLeft});
  height: 40px;
  width: 40px;
  background-size: cover;
  cursor: pointer;
`;

const RoundButton = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: flex-end;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  cursor: pointer;
`;

const modalStyles = {
  content: {
    position: 'relative',
    width: '650px',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    inset: '0px',
  },
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(128,128,128, 0.5)',
  },
};

export const Styled = {
  Wrapper,
  ContentWrapper,
  BackButton,
  TopButtonsWrapper,
  RoundButton,
  modalStyles,
};
