import styled from 'styled-components';

const Form = styled.form`
  display: block;
  margin: 0 auto;
  width: 450px;
  height: auto;
  color: ${({ theme }) => theme.colors.black};

  &>h2{
    font-weight: 400;
    margin-bottom: ${({ theme }) => theme.spaces.md};;
  }
`;

const DropzoneWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spaces.md};
`;

const ButtonsContainer = styled.div`
  display: flex;
  &>button:first-child{
    margin-right: ${({ theme }) => theme.spaces.sm};
  }
`;

const Preview = styled.img`
  width: 318px;
  height: 160px;
`;

export const Styled = {
  Form,
  ButtonsContainer,
  Preview,
  DropzoneWrapper,
};
