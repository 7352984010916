import React, { FC } from 'react';
import { NotificationModal } from '../../../common/components/notification-modal';

interface ITableCallModalProps {
  isOpen: boolean;
  onRequestClose: (event: React.MouseEvent) => void;
  tableName: string;
};

export const TableCallModal: FC<ITableCallModalProps> = ({
  isOpen,
  onRequestClose,
  tableName,
}) => {
  const title = `You are called to ${tableName}`;
  return (
    <NotificationModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      subtitle="Please approach the customer."
    />
  );
};
