import styled from 'styled-components';

const TextareaWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.sm};
`;

const Label = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spaces.xxs};
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: ${({ theme }) => theme.spaces.sm} ${({ theme }) => theme.spaces.xs};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 10px;
  &::placeholder{
    color: ${({ theme }) => theme.colors.black}
  }
`;

export const Styled = {
  TextareaWrapper,
  Textarea,
  Label,
};
