import { FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Button } from '../../../common/components/button';
import { ErrorBox } from '../../../common/components/error-box';
import { FormInput } from '../../../common/components/form-input';
import { addWaiterService } from '../../services/add-waiter.service';
import { editValidationSchema } from './utils/edit-validation-schema';
import { addValidationSchema } from './utils/add-validation-schema';
import { Styled } from './waiter-form.styled';
import { IUser } from '../../../../types';
import { IFormData, WaiterMode } from '../../types';
import { useQueryClient, useMutation } from 'react-query';
import { initialValues } from '../../constants';

interface IWaiterFormProps {
  setMode: (mode: WaiterMode) => void,
  currentWaiter: IUser | null,
}

export const WaiterForm:FC<IWaiterFormProps> = ({ currentWaiter, setMode }) => {
  const [errorAccess, setErrorAccess] = useState<boolean>(false);
  const [formData, setFormData] = useState<IFormData>(initialValues);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (currentWaiter) {
      setFormData({
        name: currentWaiter.name,
        email: currentWaiter.email,
        password: '',
      });
    }
  }, []);

  const deleteWaiter = useMutation(() => addWaiterService.deleteUser(currentWaiter!.id), {
    onSuccess: () => {
      queryClient.invalidateQueries('waiters');
      setMode('list');
    },
    onError: () => {
      setErrorAccess(true);
    },
  });

  const updateWaiter = useMutation(({ name, email, password }: IFormData) =>
    addWaiterService.updateUser(currentWaiter!.id, name, email, password), {
    onSuccess: () => {
      queryClient.invalidateQueries('waiters');
      setMode('list');
    },
    onError: () => {
      setErrorAccess(true);
    },
  });

  const registerWaiter = useMutation(({ name, email, password }: IFormData) =>
    addWaiterService.registerUser(name, email, password), {
    onSuccess: () => {
      queryClient.invalidateQueries('waiters');
      setMode('list');
    },
    onError: () => {
      setErrorAccess(true);
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formData,
    onSubmit: ({ name, email, password }:IFormData) => {
      if (currentWaiter?.id) {
        updateWaiter.mutate({ name, email, password });
      } else {
        registerWaiter.mutate({ name, email, password });
      }
    },
    validationSchema: currentWaiter?.id ? editValidationSchema : addValidationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <Styled.Form onSubmit={formik.handleSubmit}>
        <FormInput
          name="name"
          placeholder="Full name"
          type="text"
        />
        <FormInput
          name="email"
          placeholder="Email"
          type="email"
        />
        <FormInput
          name="password"
          placeholder="Password"
          type="password"
        />
        <Styled.ButtonContainer>
          <Button
            type="submit"
            background="primary"
            rounded={true}
          >Submit</Button>
          {currentWaiter?.id && (
            <Button
              type="button"
              onClick={() => {
                deleteWaiter.mutate();
              }}
              background="primary"
              rounded={true}
            >Delete User</Button>
          )}
        </Styled.ButtonContainer>
        {errorAccess && (
          <ErrorBox>
            {'Something went wrong'}
          </ErrorBox>
        )}
      </Styled.Form>
    </FormikProvider>
  );
};
