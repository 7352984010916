import React, { FC } from 'react';
import { Styled } from './table.styled';
import { TableRow } from '../table-row';
import { IMap } from '../../../../types';
import { ITableColumn } from '../../types';
import { Alignment } from '../../constants';

interface ITable {
  columns: ITableColumn[],
  data: IMap | null,
  alignment?: Alignment,
}

export const Table:FC<ITable> = ({ columns, data, alignment = Alignment.CENTER }) => {
  const tableHeadRow = columns.map((column:ITableColumn) => {
    return (
      <Styled.TableCell
        alignment={alignment}
        key={column.key}>
        {column.title}
      </Styled.TableCell>
    );
  });

  const rows = data?.map((item:IMap) =>
    <TableRow
      key={item.id}
      item={item}
      columns={columns}
    />);

  return (
    <Styled.TableWrapper>
      <Styled.TableHead>
        {tableHeadRow}
      </Styled.TableHead>
      <Styled.TableBody>
        {rows}
      </Styled.TableBody>
    </Styled.TableWrapper>
  );
};
