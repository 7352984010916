import styled from 'styled-components';

interface button {
  onClick: (event: MouseEvent) => void
};

const OrderDetails = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.md};
`;

const ViewButton = styled.button<button>`
  width: 100px;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.lg};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.secondary};
  padding: ${({ theme }) => theme.spaces.xs} 0px;
  &:hover{
    cursor: pointer;
  }
`;

export const Styled = {
  OrderDetails,
  ViewButton,
};
