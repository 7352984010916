import { ILoginResponse } from '../../../types/login';
import { HttpServiceFactory } from '../../common/services';
import { HttpService } from '../../common/services/http.service';
import { ILoginData } from '../types';


export class OwnerService {
  constructor(private httpService: HttpService) {}
  public loginUser(email: string, password: string) {
    return this.httpService.post<ILoginResponse, ILoginData>('owner-auth/login', { email, password });
  }
};

const factory = new HttpServiceFactory();
export const authOwnerService = new OwnerService(factory.createHttpService());
