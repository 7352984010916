import styled from 'styled-components';
import { IStatusIndicator } from '../../types';

const StatusIndicator = styled.span<IStatusIndicator>`
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid ${({ theme, color }) => color ? theme.colors[color] : theme.colors.white};
`;
export const Styled = {
  StatusIndicator,
};
