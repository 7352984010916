import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { ICell } from '../../../../common/types';
import { Styled } from './button-cell.styled';

export const ButtonCell:FC<ICell> = ({ item }) => {
  const history = useHistory();
  const onClickHandler = () => {
    history.push(`/orders/${item.id}`);
  };

  return (
    <Styled.OrderDetails>
      <Styled.ViewButton
        onClick={onClickHandler}
      >
          View
      </Styled.ViewButton>
    </Styled.OrderDetails>
  );
};
