import { ITable } from '../types';

export const sortTables = (data: ITable[]) => {
  return data.sort((item1:ITable, item2: ITable) => {
    if (item1.openOrders.length && item2.openOrders.length) {
      if (item1.openOrders[0].status === 'accepted') {
        return 1;
      } else if (item2.openOrders[0].status === 'accepted') {
        return -1;
      } else {
        return 0;
      }
    } else if (item1.openOrders.length) {
      return -1;
    } else if (item2.openOrders.length) {
      return 1;
    }
    return 0;
  });
};
