import React, { FC } from 'react';
import { theme } from '../../../../styles';
import { Styled } from './button.styled';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: 'submit' | 'reset' | 'button';
  background?: string;
  rounded?: boolean;
  onClick?: (event: React.MouseEvent) => Promise<void> | void | null;
  width?: string;
  height?: string;
};

export const Button:FC<ButtonProps> = ({ type, background, rounded, onClick, children, width, height, disabled }) => {
  return (
    <Styled.Button
      type={type}
      background={background}
      rounded={rounded}
      onClick={onClick}
      theme={theme}
      width={width}
      height={height}
      disabled={disabled}
    >{children}</Styled.Button>
  );
};
