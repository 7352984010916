import styled from 'styled-components';

const CenteredFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fonts.xl};
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.3px;
`;

const Content = styled(CenteredFlex)`
  flex-direction: column;
`;

const IconWrapper = styled(CenteredFlex)`
  width: 69px;
  height: 69px;
  margin-bottom: ${({ theme }) => theme.spaces.xs};
  font-size: ${({ theme }) => theme.fonts.xxl};
`;

const TextWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.md};
`;

const Title = styled(Text)`
  margin: 0;
`;

const ButtonText = styled(Text)``;

export const Styled = {
  Content,
  IconWrapper,
  TextWrapper,
  Title,
  ButtonText,
};
