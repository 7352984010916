import styled from 'styled-components';

const AppWrapper = styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.colors.primary};
`;
const Content = styled.div`
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    padding-top: ${({ theme }) => theme.spaces.xxl};
    background-color: ${({ theme }) => theme.colors.background};
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
`;
export const Styled = {
  AppWrapper,
  Content,
};
