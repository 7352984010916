import * as Yup from 'yup';
import { getEmailValidator } from '../../../../common/utils/get-email-validator';
import { getNameValidator } from '../../../../common/utils/get-name-validator';
import { getWaiterPasswordValidator } from '../../../../common/utils/get-waiter-password-validator';

export const editValidationSchema = Yup.object().shape({
  name: getNameValidator(),
  email: getEmailValidator(),
  password: getWaiterPasswordValidator(),
});
