import styled from 'styled-components';

const OrderDishes = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Styled = {
  OrderDishes,
};
