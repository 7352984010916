import React, { FC, useEffect, useState } from 'react';
import { InnerTitle } from '../inner-title';
import { Table } from '../../../common/components/table';
import { TableNumberCell } from '../order-table-cells/table-number-cell';
import { OrderStatusCell } from '../order-table-cells/order-status-cell';
import { ButtonCell } from '../order-table-cells/button-cell';
import { CreatedAtCell } from '../order-table-cells/text-cell';
import { sortOrdersDataByStatus } from '../../utils/sort-orders';
import { ITableColumn, TableCellType } from '../../../common/types';
import { IOrdersResponse } from '../../types';
import { socketService } from '../../../common/services/socket-service';
import { socketEvents } from '../../../common/constants';

export const OrdersOngoing:FC = ({}) => {
  const [ongoingOrders, setOngoingOrders] = useState<IOrdersResponse | null>(null);

  useEffect(() => {
    socketService.socket?.on(socketEvents.getOrders, setOngoingOrders);
    if (!ongoingOrders) {
      socketService.socket?.emit('order');
    }

    return () => {
      socketService.socket?.off(socketEvents.getOrders, setOngoingOrders);
    };
  }, []);

  const columns:ITableColumn[] = [
    {
      title: 'Table number',
      key: 'table',
      type: TableCellType.TableNumber,
      Component: TableNumberCell,
    },
    {
      title: 'Order time',
      key: 'createdAt',
      type: TableCellType.CreatedAt,
      Component: CreatedAtCell,
    },
    {
      title: 'Order status',
      key: 'status',
      type: TableCellType.OrderStatus,
      Component: OrderStatusCell,
    },
    {
      title: 'View order detail',
      key: 'view',
      type: TableCellType.Button,
      Component: ButtonCell,
    },
  ];

  const sortedData = ongoingOrders?.order ? sortOrdersDataByStatus(ongoingOrders) : null;

  return (
    <>
      <InnerTitle>Orders Placed</InnerTitle>
      {
        (
          <Table
            columns={columns}
            data={sortedData}
          />
        )
      }
    </>
  );
};
