/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { fileService } from '../../services/file.service';
import { Styled } from './dropzone.styled';
import { getIn, useFormikContext } from 'formik';
import { fileValidationSchema } from '../../utils/dropzone-validation-schema';
import { ErrorBox } from '../error-box';

interface IImageDropzoneProps {
  setFileData: CallableFunction;
  setFormikMedia: CallableFunction;
}

export const ImageDropzone: React.FC<IImageDropzoneProps> = ({
  setFileData,
  setFormikMedia,
}) => {
  const [dropzoneError, setDropzoneError] = useState<string>('');
  const { errors, touched } = useFormikContext();
  const isErrorExists = getIn(errors, 'media') && getIn(touched, 'media');

  const onDrop = (files: File[]) => {
    fileValidationSchema.validate({
      files,
    }).then(async ({ files }) => {
      const base64File = await fileService.convertBase64(files[0]);
      setFileData(base64File);
      setFormikMedia();
    }).catch((error: Error) => {
      setDropzoneError(error.message);
    });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <Styled.DropzoneContainer>
      <Styled.Dropzone { ...getRootProps({ className: 'dropzone' }) }>
        <input
          { ...getInputProps() }
        />
        <Styled.Text>
        Drag &apos;n&apos; drop svg/png img here, or click to select file
        </Styled.Text>
      </Styled.Dropzone>
      {dropzoneError && (
        <ErrorBox>{dropzoneError}</ErrorBox>
      )}
      {isErrorExists && (
        <ErrorBox>{getIn(errors, 'media')}</ErrorBox>
      )}
    </Styled.DropzoneContainer>
  );
};
