import styled from 'styled-components';

const TabsWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spaces.xs};
`;

export const Styled = {
  TabsWrapper,
};
