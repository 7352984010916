
export class DateService {
  constructor() {};
  public timeStringToDate = (date: string): number => {
    return new Date(date).getTime();
  }

  public compareTimeStrings(date1: string, date2: string): number {
    if (this.timeStringToDate(date1) === this.timeStringToDate(date2)) {
      return 0;
    }
    return this.timeStringToDate(date1) > this.timeStringToDate(date2) ? 1 : -1;
  }

  public stringToLocaleDate = (date: string) => {
    return new Date(date).toLocaleString();
  }
};

export const dateService = new DateService();
