import { HttpServiceFactory } from './index';
import { IProfile } from '../../../types';
import { ROUTES } from '../constants';
import { EnhancedWithAuthHttpService } from './http-auth.service';

export class UserService {
  constructor(private httpService: EnhancedWithAuthHttpService) {};
  public async getProfile(): Promise<IProfile | void> {
    return await this.httpService.get(ROUTES.PROFILE);
  };
};

const factory = new HttpServiceFactory();
export const userService = new UserService(factory.createAuthHttpService());
