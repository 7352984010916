import styled from 'styled-components';

interface ITab {
  isActive?: boolean;
}

const Tab = styled.div<ITab>`
  border-color: ${({ isActive, theme }) => isActive ? theme.colors.primary : 'transparent'};
  color: ${({ isActive, theme }) => isActive ? theme.colors.primary : theme.colors.black};
  border-width: 1px;
  border-style:  ${({ isActive }) => isActive ? 'solid' : 'none'};
  padding: ${({ theme }) => theme.spaces.xxs} ${({ theme }) => theme.spaces.xs};
  margin-right: ${({ theme }) => theme.spaces.xs};

  &:hover{
    cursor: pointer;
  }
`;

export const Styled = {
  Tab,
};
