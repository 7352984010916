import styled from 'styled-components';

const TableName = styled.div`
  width: 100%;
  text-align: start;
  padding: ${({ theme }) => theme.spaces.sm} ${({ theme }) => theme.spaces.lg};
  word-break: break-all;
`;

export const Styled = {
  TableName,
};
